import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addDish, removeDish, setCarrierBagAtLeastOne } from 'status/partial/cart';
import { setOrderTypeToDelivery, setOrderTypeToPickUp } from 'status/partial/orderinfo';

export default function (PurchaseSidebar) {
  function PurchaseSidebarWrapper(props) {
    return (
      <PurchaseSidebar
        {...props}
      />
    );
  }

  return connect(state => ({
    cart: state.cart,
    orderinfo: state.orderinfo,
    promotion: state.promotion,
    menu: state.menu,
    configuration: state.configuration,
  }), dispatch => ({
    ...bindActionCreators({ addDish, removeDish, setCarrierBagAtLeastOne }, dispatch),
    ...bindActionCreators({ setOrderTypeToDelivery, setOrderTypeToPickUp }, dispatch),
  }))(PurchaseSidebarWrapper);
}
