import { mediaLarge, mediaMedium } from 'themes/mixins';

export default theme => ({
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(),
  },
  cardWrap: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  card: {
    cursor: 'pointer',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  textCenter: {
    textAlign: 'center',
  },
  ...mediaMedium({
    cardWrap: {
      width: '50%',
      padding: theme.spacing(),
    },
    table: {
      minWidth: 400,
    },
  }),
  ...mediaLarge({
    cardWrap: {
      width: '25%',
      padding: theme.spacing(),
    },
    table: {
      minWidth: 700,
    },
  }),
});
