
import { colors } from 'themes/variables';

export default () => ({
  formWrapper: {
    maxWidth: 600,
  },
  light: {
    color: colors.primary,
    textDecoration: 'none',
    cursor: 'pointer',
  },
});
