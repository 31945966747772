import moment from 'moment';
import { isEmpty, get } from 'lodash';
import { findTargetPromotion } from 'utils/promotionTools';

export function targetDiscountValue(orderSkuPrmotions, created) {
  const params = orderSkuPrmotions.map(item => ({
    ...item,
    joined_merchandises: [-1],
  }));
  const target = findTargetPromotion(-1, params, moment(created).toDate());
  if (isEmpty(target)) {
    return 1;
  }
  return target.detail.value;
}

export function getUnitSkuItemPrice(orderSkuItem, created) {
  const extraAmount = orderSkuItem.orderextra_set.reduce(
    (x, y) => x + (y.price * y.count),
    0,
  );
  const discountValue = targetDiscountValue(
    get(orderSkuItem, 'ordermerchandisepromotion_set', []),
    created,
  );
  return (orderSkuItem.price * discountValue) + extraAmount;
}

export function getSkuItemPrice(orderSkuItem, created) {
  return getUnitSkuItemPrice(orderSkuItem, created) * get(orderSkuItem, 'count', 0);
}


export function getOrderAmount(order) {
  if (isEmpty(order.ordersku_set)) {
    return 0;
  }
  return order.ordersku_set.reduce(
    (x, y) => x + getSkuItemPrice(y, order.created),
    0,
  );
}
