import axios from 'axios';
import { attachTime /* , errorInterceptor */} from './interceptors';

const request = axios.create();
request.interceptors.request.use(attachTime);
// request.interceptors.response.use(pass, errorInterceptor);

const requestWidthAuth = axios.create();
requestWidthAuth.interceptors.request.use(attachTime);
// requestWidthAuth.interceptors.response.use(pass, errorInterceptor);

export {
  request,
  requestWidthAuth,
};
