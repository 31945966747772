import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MailIcon from '@material-ui/icons/Mail';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field, reduxForm, propTypes, Form, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import EmailField from 'components/presentations/Fields/EmailField';
import NameField from 'components/presentations/Fields/NameField';
import TelephoneField from 'components/presentations/Fields/TelephoneField';
import styles from './styles';

class UserInfomationForm extends React.PureComponent {
  render() {
    const { classes, handleSubmit, submitting, values } = this.props;
    return (
      <div>
        <Form noValidate onSubmit={handleSubmit} autoComplete="off">
          <Field
            name="name"
            component={NameField}
          />
          <TextField
            value={values.email}
            label="Indtast din e-mail adresse"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MailIcon />
                </InputAdornment>
              ),
            }}
            margin="normal"
            disabled
            fullWidth
          />
          <div className={classes.hide}>
            <Field
              name="email"
              component={EmailField}
            />
          </div>
          <Field
            name="phone_number"
            component={TelephoneField}
          />
          <div className={classes.padding}>
            {
              submitting ?
                <Button color="primary" variant="contained" fullWidth disabled>
                  <CircularProgress size={20} />
                </Button>
                :
                <Button color="primary" variant="contained" fullWidth type="submit">Gem</Button>
            }
          </div>
        </Form>
      </div>
    );
  }
}

UserInfomationForm.propTypes = {
  ...propTypes,
  classes: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
};
const selector = formValueSelector('userInfomation');
export default reduxForm({
  form: 'userInfomation',
})(connect(state => ({
  store: state.store,
  values: {
    name: selector(state, 'name'),
    email: selector(state, 'email'),
    phone_number: selector(state, 'phone_number'),
  },
}))(withStyles(styles)(UserInfomationForm)));
