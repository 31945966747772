
export default theme => ({
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  title: {
    marginBottom: 20,
  },
  padding: {
    paddingTop: 0,
    paddingBottom: theme.spacing(),
  },
  label: {
    marginTop: 20,
  },
});
