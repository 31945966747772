import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import LockOutLineIcon from '@material-ui/icons/Lock';
import InputAdornment from '@material-ui/core/InputAdornment';

function PasswordField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      label="Bekræft kodeord"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <LockOutLineIcon />
          </InputAdornment>
        ),
      }}
      type="password"
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

PasswordField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

export default PasswordField;
