import { get, omit, isEmpty, isNumber, pickBy } from 'lodash';
import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;

export function signin(params) {
  return request.post(`${apiUrlV1}/login/`, params);
}

export function getDinerInfo(params) {
  return request.get(`${apiUrlV1}/diner/`, { params });
}

export function createDinerDeliveryInfo(params) {
  return request.post(`${apiUrlV1}/diner_address/`, {
    ...{ country: 1 },
    ...pickBy(params, key => !isEmpty(key) || isNumber(key)),
  });
}

export function modifyDinerDeliveryInfo(params) {
  const id = get(params, 'id', 0);
  return request.patch(`${apiUrlV1}/diner_address/${id}/`, {
    ...{ country: 1 },
    ...pickBy(omit(params, ['id']), key => !isEmpty(key) || isNumber(key)),
  });
}
