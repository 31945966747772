import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { push } from 'react-router-redux';
import { push } from 'connected-react-router';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { isEmpty, get } from 'lodash';
import qs from 'qs';
import { simplifyTargetCartItem, getDefaultCartItem } from 'status/partial/cart';
import formatCurrency from 'utils/formatCurrency';
import { getOrderByCode } from 'apis/services/order';
import isShowCartItemDetail from 'utils/isShowCartItemDetail';
import {
  getTotalCartItemDiscount,
  totalDiscountOfCart,
  getAmountWithCoupon,
} from 'utils/getCartAmount';
import styles, { tableCellStyles } from './styles';
import failcode from './failcode';

const CustomTableCell = withStyles(tableCellStyles)(TableCell);

function getCartItemKey(cartItem) {
  const target = simplifyTargetCartItem(cartItem);
  return JSON.stringify(target);
}

class FreepayFail extends React.PureComponent {
  state = {
    isOpenDetailModal: false,
    currentDish: getDefaultCartItem(),
    currentOrder: {
      delivery_amount: 0,
      ordersku_set: [],
      created: '1970-01-01T00:00:00.0Z',
    },
  }

  componentDidMount() {
    const { orderinfo, router } = this.props;
    let orderCode = qs.parse(router.location.search.slice(1)).order_code;
    const gatewayreason = qs.parse(window.location.search.slice(1)).gatewayreason;
    if (orderCode == null) {
      orderCode = orderinfo.order_code;
    }
    getOrderByCode({
      order_code: orderCode,
    }).then((res) => {
      this.setState({
        gatewayreason,
        currentOrder: res,
      });
    });
  }

  back = () => {
    this.props.dispatch(push({
      pathname: '/',
    }));
  }

  render() {
    const { classes, cart, orderinfo, promotion } = this.props;
    const { currentOrder, gatewayreason } = this.state;
    const discountedPrice = totalDiscountOfCart(cart, promotion, new Date());
    const amountWithCoupon = getAmountWithCoupon(discountedPrice, get(orderinfo, 'coupon', {}));

    return (
      <div className={classes.wrapper}>
        <Topbar />
        <div className={classes.content}>
          <div className={classes.inner}>
            <Typography className={classes.title} variant="body2" align="center" gutterBottom>Payment Declined</Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>Order Nr. { currentOrder.order_code }</Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>
              {
                isEmpty(get(failcode, gatewayreason, '')) ?
                  ''
                  :
                  failcode[gatewayreason]
              }
            </Typography>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>Navn</CustomTableCell>
                    <CustomTableCell align="right">Antal</CustomTableCell>
                    <CustomTableCell align="right">Prisr</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cart.map(n => (
                    <TableRow className={classes.row} key={getCartItemKey(n)}>
                      <CustomTableCell
                        component="th"
                        scope="row"
                        className={classNames({
                          [classes.more]: isShowCartItemDetail(n),
                        })}
                      >
                        {n.sku.name}
                      </CustomTableCell>
                      <CustomTableCell align="right">{n.count}</CustomTableCell>
                      <CustomTableCell align="right">
                        {
                          formatCurrency(
                            getTotalCartItemDiscount(n, promotion, new Date()),
                          )
                        }
                      </CustomTableCell>
                    </TableRow>
                  ))}
                  {
                    !isEmpty(get(currentOrder, 'coupon', {})) &&
                      <React.Fragment>
                        <TableRow className={classes.row}>
                          <CustomTableCell component="th" scope="row">
                            Sum priser
                          </CustomTableCell>
                          <CustomTableCell align="right" />
                          <CustomTableCell align="right">
                            {
                              formatCurrency(
                                discountedPrice,
                              )
                            }
                          </CustomTableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                          <CustomTableCell component="th" scope="row">
                            Kupon
                          </CustomTableCell>
                          <CustomTableCell align="right" />
                          <CustomTableCell align="right">
                            {
                              formatCurrency(
                                0 - (discountedPrice - amountWithCoupon),
                              )
                            }
                          </CustomTableCell>
                        </TableRow>
                      </React.Fragment>
                  }
                  <TableRow className={classes.row}>
                    <CustomTableCell component="th" scope="row">
                      Lerveringgebyr
                    </CustomTableCell>
                    <CustomTableCell align="right" />
                    <CustomTableCell align="right">
                      { formatCurrency(orderinfo.delivery_amount) }
                    </CustomTableCell>
                  </TableRow>
                  {
                    currentOrder.payment_type === 'card' &&
                    <TableRow className={classes.row}>
                      <CustomTableCell component="th" scope="row">
                        Håndterings Gebyr
                      </CustomTableCell>
                      <CustomTableCell align="right" />
                      <CustomTableCell align="right">
                        { formatCurrency(1.89) }
                      </CustomTableCell>
                    </TableRow>
                  }
                  <TableRow className={classes.row}>
                    <CustomTableCell component="th" scope="row">
                      Total Priser
                    </CustomTableCell>
                    <CustomTableCell align="right" />
                    <CustomTableCell align="right">
                      {
                        currentOrder.payment_type === 'card' ?
                          formatCurrency(
                            amountWithCoupon + orderinfo.delivery_amount + 1.89,
                          )
                          :
                          formatCurrency(
                            amountWithCoupon + orderinfo.delivery_amount,
                          )
                      }
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <Button color="primary" variant="outlined" onClick={this.back} className={classes.submit}>Tilbage til</Button>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

FreepayFail.propTypes = {
  classes: PropTypes.shape().isRequired,
  orderinfo: PropTypes.shape().isRequired,
  router: PropTypes.shape().isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    cart: state.cart,
    orderinfo: state.orderinfo,
    promotion: state.promotion,
    router: state.router,
  }),
  dispatch => ({
    dispatch,
  }),
)(withStyles(styles)(FreepayFail));
