export default {
  // '20000': 'Success',
  30000: 'Declined by 3D-secure',
  30100: '3D-secure authentication failure.',
  30200: 'Additional authentication needed (3D-secure)',
  40000: 'Acquirer declined',
  40010: 'Suspected fraud',
  40100: 'Issuer declined',
  40110: 'Insufficient funds on card',
  40120: 'Withdrawal limit exceeded',
  40130: 'Merchant blocked by cardholder',
  40200: 'Invalid card',
  40210: 'Card expired',
  40220: 'Invalid card security code',
  40230: 'Invalid card expiration',
  40240: 'Lost or stolen card',
  40250: 'Card restricted',
  40260: 'Unsupported card scheme',
  50000: 'Internal error',
  50010: 'Internal communications error',
  50100: 'Acquirer communications error',
  50200: 'Acquirer protocol error',
  50300: 'Acquirer internal error',
  50400: 'Issuer internal error',
};
