import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import AccountIcon from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';

function NameField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      label="Navn"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <AccountIcon />
          </InputAdornment>
        ),
      }}
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

NameField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

export default NameField;
