import getCouponList from 'apis/services/coupon';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

const FETCH_COUPON = Symbol('FETCH_COUPON');
const FETCH_COUPON_SUCCESS = Symbol('FETCH_COUPON_SUCCESS');
const EMPTY_COUPON = Symbol('EMPTY_COUPON');

export const actionTypes = {
  FETCH_COUPON,
  FETCH_COUPON_SUCCESS,
  EMPTY_COUPON,
};

export const initialState = [];

export const fetchCoupon = params => ({ type: FETCH_COUPON, payload: params });

export const fetchCouponSuccess = response => ({
  type: FETCH_COUPON_SUCCESS,
  payload: response,
});

export const resetCouponList = () => ({
  type: EMPTY_COUPON,
});

export const fetchCouponEpic = action$ =>
  action$.ofType(FETCH_COUPON).mergeMap(action =>
    Observable.fromPromise(getCouponList(action.payload))
      .map(response => fetchCouponSuccess(response)),
  );

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUPON_SUCCESS:
      return [...action.payload];
    case EMPTY_COUPON:
      return [];
    default:
      return state;
  }
};
