import { setLocalStorage, removeLocalStorage } from 'utils/localStorage';
import { actionTypes as authActionTypes } from 'status/partial/auth';

const saveAuthToLocal = store => next => (action) => {
  const result = next(action);
  const state = store.getState();
  switch (action.type) {
    case authActionTypes.SAVE_AUTH:
      setLocalStorage('auth', JSON.stringify(state.auth));
      break;
    case authActionTypes.RESET_AUTH:
      removeLocalStorage('auth');
      break;
    default:
      break;
  }
  return result;
};

export default saveAuthToLocal;
