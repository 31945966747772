import { get } from 'lodash';
import * as Sentry from '@sentry/browser';

export const pass = response => response;

export const attachTime = (config) => {
  if (/get/i.test(config.method)) {
    return {
      ...config,
      ...{
        params: {
          ...config.params,
          __timestamp__: Date.parse(new Date()),
        },
      },
    };
  }
  return config;
};

export function errorInterceptor(error) {
  if (process.env.NODE_ENV === 'production') {
    Sentry.withScope((scope) => {
      scope.setTag('type', 'request error');
      scope.setExtra('url', error.config.url);
      scope.setExtra('method', error.config.method);
      scope.setExtra('error_data', error);
      scope.setExtra('request_payload', error.config.data);
      scope.setExtra('response_data', get(error, 'response.data', {}));
      Sentry.captureException(error);
    });
  }
  return Promise.reject(error);
}

export function unauthorizedInterceptor(error) {
  if (process.env.NODE_ENV === 'production') {
    Sentry.withScope((scope) => {
      scope.setTag('type', 'request error');
      scope.setExtra('url', error.config.url);
      scope.setExtra('method', error.config.method);
      scope.setExtra('error_data', error);
      scope.setExtra('request_payload', error.config.data);
      scope.setExtra('response_data', get(error, 'response.data', {}));
      Sentry.captureException(error);
    });
  }
  return Promise.reject(error);
}
