import { first, findIndex, isEmpty } from 'lodash';
import { isDatetimeInWeeklySchedule, isTargetDatetimeInRange } from 'utils/timeTools';
import moment from 'moment';

export function findTargetPromotion(spuID, promotion, targetTime) {
  const targetPromotionList = promotion.filter(
    item => findIndex(item.joined_merchandises, id => id === spuID) >= 0,
  ).filter(
    item => isTargetDatetimeInRange(
      targetTime,
      moment(item.start_time).toDate(),
      moment(item.end_time).toDate(),
    ),
  ).filter(
    item => isDatetimeInWeeklySchedule(targetTime, item.active_weekly_schedule),
  );
  const targetPromotion = first(targetPromotionList.sort(
    (x, y) => x.detail.value - y.detail.value,
  ));
  if (isEmpty(targetPromotion)) {
    return {};
  }
  return targetPromotion;
}

export const just = isDatetimeInWeeklySchedule;
