export function setLocalStorage(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    return undefined;
  }
  return undefined;
}

export function getLocalStorage(key) {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    return null;
  }
}

export function removeLocalStorage(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    return undefined;
  }
  return undefined;
}
