import auth from './auth';
import diner from './diner';
import category from './category';
import store from './store';
import cart from './cart';
import orderinfo from './orderinfo';
import delivery from './delivery';
import addresses from './addresses';
import menu from './menu';
import promotion from './promotion';
import couponPromotion from './couponpromotion';
import configuration from './configuration';
import coupon from './coupon';
import orderhistory from './orderhistory';

export default {
  auth,
  diner,
  category,
  store,
  cart,
  orderinfo,
  delivery,
  addresses,
  menu,
  promotion,
  configuration,
  orderhistory,
  coupon,
  couponPromotion,
};
