import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, propTypes, Form, formValueSelector } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmailField from 'components/presentations/Fields/EmailField';
import NameField from 'components/presentations/Fields/NameField';
import TelephoneField from 'components/presentations/Fields/TelephoneField';
import styles from './styles';

// street name
function VejField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      placeholder="Vej"
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

VejField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

// house number
function HusnummerField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      placeholder="Husnummer"
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

HusnummerField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

// floor number
function EtageField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      placeholder="Etage (valgfri)"
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

EtageField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

function CityField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      label="By eller område"
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

CityField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

function PostnummerField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      placeholder="Postnummer"
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

PostnummerField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

class UserAddressForm extends React.PureComponent {
  render() {
    const { classes, handleSubmit, submitting } = this.props;
    return (
      <div>
        <Form noValidate onSubmit={handleSubmit} autoComplete="off">
          <Field
            name="contact_name"
            component={NameField}
          />
          <Field
            name="contact_phone"
            component={TelephoneField}
          />
          <Field
            name="contact_email"
            component={EmailField}
          />
          <Field
            name="street_name"
            component={VejField}
          />
          <Field
            name="house_number"
            component={HusnummerField}
          />
          <Field
            name="floor_number"
            component={EtageField}
          />
          <Field
            name="post_number"
            component={PostnummerField}
          />
          <Field
            name="city_name"
            component={CityField}
          />
          <div className={classes.padding}>
            {
              submitting ?
                <Button color="primary" variant="contained" fullWidth disabled>
                  <CircularProgress size={20} />
                </Button>
                :
                <Button color="primary" variant="contained" fullWidth type="submit">Gem</Button>
            }
          </div>
        </Form>
      </div>
    );
  }
}

UserAddressForm.propTypes = {
  ...propTypes,
  classes: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
};
const selector = formValueSelector('userAddress');
export default reduxForm({
  form: 'userAddress',
})(connect(state => ({
  store: state.store,
  values: {
    name: selector(state, 'name'),
    email: selector(state, 'email'),
    phone_number: selector(state, 'phone_number'),
  },
}))(withStyles(styles)(UserAddressForm)));
