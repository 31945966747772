import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import LocationIcon from '@material-ui/icons/Place';
import HomeIcon from '@material-ui/icons/Home';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function RestaurantInfoBar(props) {
  const { classes, name, address, cellphone } = props;
  return (
    <div className={classes.root}>
      <Typography>
        <LocationIcon className={classes.icon} />
        { name }
        <span className={classes.separator}>/</span>
        <HomeIcon className={classes.icon} />
        { address }
        <span className={classes.separator}>/</span>
        <LocalPhoneIcon className={classes.icon} />
        { cellphone }
      </Typography>
    </div>
  );
}

RestaurantInfoBar.propTypes = {
  classes: PropTypes.shape().isRequired,
  name: PropTypes.string,
  address: PropTypes.string,
  cellphone: PropTypes.string,
};

RestaurantInfoBar.defaultProps = {
  name: '',
  address: '',
  cellphone: '',
};

export default withStyles(styles)(RestaurantInfoBar);
