import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

class RemoveCarrierBagModal extends React.PureComponent {
  handleOkayClick = () => {
    this.props.handleOkayClick();
  }

  handleCancelClick = () => {
    this.props.handleCancelClick();
  }

  render() {
    const { open, classes } = this.props;

    return (
      <Dialog
        open={open}
        onClose={() => null}
      >
        <DialogTitle>Varsel</DialogTitle>
        <DialogContent className={classes.content}>
          <Typography>
            Husk medbringer jeres egen fornuftigt poser til afhentning af mad
            , eller tilføje en lovpligtig bæreposeafgift på 4 kr. pr. pose.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleOkayClick}
            color="primary"
          >
            Okay
          </Button>
          <Button
            onClick={this.handleCancelClick}
          >
            Afbestille
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

RemoveCarrierBagModal.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  open: PropTypes.bool,
  handleOkayClick: PropTypes.func,
  handleCancelClick: PropTypes.func,
};

RemoveCarrierBagModal.defaultProps = {
  open: false,
  handleOkayClick: () => null,
  handleCancelClick: () => null,
};

export default withStyles(styles)(RemoveCarrierBagModal);
