import moment from 'moment';
import { get, isEmpty } from 'lodash';

export function getWeekmap() {
  return {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
  };
}

export function isTargetDatetimeInRange(targetDateTime, startDatetime, endDateTime) {
  return startDatetime <= targetDateTime && targetDateTime <= endDateTime;
}

export function hasDatetimeIntersection(startTimeA, endTimeA, startTimeB, endTimeB) {
  if (Math.max(startTimeA, endTimeA) < Math.min(startTimeB, endTimeB)) {
    return false;
  }
  return true;
}

export function isDatetimeInWeeklySchedule(targetDatetime, weeklySchedule) {
  const now = moment(targetDatetime);
  const todayStr = now.format('YYYY-MM-DD');
  const weekmap = getWeekmap();
  const weekday = targetDatetime.getDay();
  const scheduleKey = get(weekmap, weekday, '');
  const day = get(weeklySchedule, scheduleKey, {});
  if (isEmpty(day)) {
    return false;
  }
  const start = moment(`${todayStr} ${get(day, 'starttime', '00:00:00')}`).toDate();
  const end = moment(`${todayStr} ${get(day, 'endtime', '00:00:00')}`).toDate();
  return start <= targetDatetime && targetDatetime <= end;
}

export function timeformat(string) {
  const mon = moment(string);
  return mon.format('YYYY-MM-DD HH:mm:ss');
}
