import { colors } from 'themes/variables';
import { mediaLarge } from 'themes/mixins';

export default theme => ({
  card: {
    margin: '16px 16px',
  },
  media: {
    height: 0,
    paddingTop: '33%',
    minWidth: '33%',
    maxWidth: '33%',
    cursor: 'pointer',
    // alignSelf: 'center',
  },
  header: {
    padding: 0,
  },
  headerTitle: {
    fontWeight: 500,
    fontSize: '1rem',
  },
  action: {
    // marginRight: theme.spacing(),
    // paddingRight: 0,
  },
  addIcon: {
    fontSize: '150%',
    color: colors.primary,
  },
  addIconMore: {
    fontSize: '150%',
    color: colors.success,
  },
  content: {
    position: 'relative',
    display: 'flex',
  },
  contentInner: {
    flex: '1 1 auto',
    padding: theme.spacing(),
    paddingTop: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(4),
    },
  },
  desc: {
    fontSize: '0.8rem',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  originPrice: {
    marginRight: theme.spacing(2),
    color: colors.secondary,
    display: 'inline-block',
  },
  price: {
    color: colors.focus,
    display: 'inline-block',
  },
  priceWrapper: {
    position: 'absolute',
    right: theme.spacing(),
    bottom: theme.spacing(),
  },
  modalImage: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 400,
    margin: 'auto',
  },
  ...mediaLarge({
    content: {
      display: 'flex',
    },
    media: {
      margin: theme.spacing(2),
      maxWidth: `${parseInt(140 * (16 / 9), 10)}px`,
      minWidth: `${parseInt(140 * (16 / 9), 10)}px`,
      height: 140,
      paddingTop: 0,
    },
    desc: {
      display: 'block',
      maxHeight: 600,
    },
    headerTitle: {
      fontSize: '1.5rem',
    },
    price: {
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },
    originPrice: {
    },
    contentInner: {
      '&:last-child': {
        paddingBottom: theme.spacing(6),
      },
    },
  }),
});
