import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import styles from './styles.js';

const CategoryList = props => (
  <React.Fragment>
    <ListItem
      className={classNames(props.classes.head, props.classes.item)}
      onClick={() => props.onChange(-1)}
      button
    >
      <ListItemIcon>
        <MenuIcon />
      </ListItemIcon>
      <ListItemText primary="Kategorier" />
    </ListItem>
    <Divider />
    <List component="nav">
      {
        props.category.map(item => (
          <ListItem
            onClick={() => props.onChange(item.id)}
            key={`category_${item.id}`}
            className={
              classNames({
                [props.classes.item]: true,
                [props.classes.active]: props.selectedId === item.id,
              })
            }
            button
          >
            <ListItemText>
              <span>
                {item.name}
              </span>
            </ListItemText>
          </ListItem>
        ))
      }
    </List>
  </React.Fragment>
);

CategoryList.propTypes = {
  category: PropTypes.arrayOf(PropTypes.shape()),
  classes: PropTypes.shape().isRequired,
  selectedId: PropTypes.number,
  onChange: PropTypes.func,
};

CategoryList.defaultProps = {
  category: [],
  selectedId: -1,
  onChange: () => null,
};

export default withStyles(styles)(CategoryList);
