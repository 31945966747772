import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

export const monochrome = {
  white: '#fff',
  black: '#000',
};

export const colors = {
  primary: amber[600],
  undertone: grey[50],
  secondary: grey[500],
  focus: red[500],
  dark: 'rgba(0, 0, 0, 0.87)',
  success: green[500],
};

export const screens = {
  xl: 1280,
  lg: 960,
  md: 600,
};

export const footerHeight = 150;
export const fontSize = 14;
