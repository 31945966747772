import PropTypes from 'prop-types';

export const getDefaultPaginationStatus = () => ({
  next: '',
  previous: '',
  current_page: 1,
  default_page_size: 20,
  count: 0,
  results: [],
});

export const paginationPropTypes = PropTypes.shape({
  next: PropTypes.string,
  previous: PropTypes.string,
  current_page: PropTypes.number,
  default_page_size: PropTypes.number,
  count: PropTypes.number,
  results: PropTypes.arrayOf(PropTypes.shape({})),
});
