
export default theme => ({
  padding: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  hide: {
    display: 'none',
  },
});
