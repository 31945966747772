import { getLocalStorage } from 'utils/localStorage';

const SET_AUTH = Symbol('SET_AUTH');
const RESET_AUTH = Symbol('RESET_AUTH');
const SAVE_AUTH = Symbol('SAVE_AUTH');

export const actionTypes = {
  SET_AUTH,
  SAVE_AUTH,
  RESET_AUTH,
};

function getInitalAuth() {
  const localAuth = getLocalStorage('auth');
  if (localAuth != null) {
    return JSON.parse(localAuth);
  }
  return {};
}

export const initialState = getInitalAuth();

export const setAuth = auth => ({ type: SET_AUTH, payload: auth });
export const saveAuth = () => ({ type: SAVE_AUTH });
export const resetAuth = () => ({ type: RESET_AUTH });

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_AUTH:
      return {};
    case SAVE_AUTH:
      return state;
    default:
      return state;
  }
};
