
import { mediaLarge } from 'themes/mixins';

export default theme => ({
  head: theme.mixins.toolbar,
  ...mediaLarge({
    wrapper: {
      display: 'none',
    },
  }),
});
