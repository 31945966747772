import { findIndex, cloneDeep, isEqual, isNumber, get, isEmpty } from 'lodash';
import { getSessionStorage } from 'utils/sessionStorage';

const ADD_DISH = Symbol('ADD_DISH');
const REMOVE_DISH = Symbol('REMOVE_DISH');
const RESET_CART = Symbol('RESET_CART');
const ADD_MULTIPLE_DISH = Symbol('ADD_MULTIPLE_DISH');


export const actionTypes = {
  ADD_DISH,
  ADD_MULTIPLE_DISH,
  REMOVE_DISH,
  RESET_CART,
};

function initState() {
  const sessionCart = getSessionStorage('cart');
  if (sessionCart != null) {
    return JSON.parse(sessionCart);
  }
  return [];
}

export const initialState = initState();
export const defaultScheme = {
  sku: {
    properties: [],
    price: 0,
  },
  storeCategory: {},
  extras: [],
  attachProperties: [],
  count: 1,
};
export const getDefaultCartItem = () => cloneDeep(defaultScheme);

export const addDish = dish => ({ type: ADD_DISH, payload: dish });
export const addMultipleDish = payload => ({ type: ADD_MULTIPLE_DISH, payload });
export const removeDish = (dish, minimun) => {
  if (isNumber(minimun) && minimun > 1) {
    return {
      type: REMOVE_DISH,
      payload: {
        ...dish,
        minimun,
      },
    };
  }
  return {
    type: REMOVE_DISH,
    payload: {
      ...dish,
      minimun: 1,
    },
  };
};
export const resetCart = () => ({ type: RESET_CART, payload: [] });

export function simplifyCart(cart) {
  return cart.map(item => ({
    sku: item.sku.id,
    extras: item.extras.map(ext => ({
      extra: ext.extra.id,
      count: ext.count,
    })).sort((x, y) => x.extra - y.extra),
    parts: item.parts.map(part => ({
      part: part.part.id,
      count: part.count,
    })).sort((x, y) => x.part - y.part),
    attachProperties: item.attachProperties.sort(
      (x, y) => x.id - y.id,
    ),
  }));
}

export function simplifyTargetCartItem(cartItem) {
  return {
    sku: cartItem.sku.id,
    extras: cartItem.extras.map(ext => ({
      extra: ext.extra.id,
      count: ext.count,
    })).sort((x, y) => x.extra - y.extra),
    parts: cartItem.parts.map(part => ({
      part: part.part.id,
      count: part.count,
    })).sort((x, y) => x.part - y.part),
    attachProperties: cartItem.attachProperties.sort(
      (x, y) => x.id - y.id,
    ),
  };
}

// return the new cart item index if has the same item which already in the cart,
// or else return -1.
export function getCartItemIndex(cart, targetCartItem) {
  const simplifyCartResult = simplifyCart(cart);
  const simplifyTargetCartItemResult = simplifyTargetCartItem(targetCartItem);
  return findIndex(simplifyCartResult, item => isEqual(item, simplifyTargetCartItemResult));
}

const defaultSku = {
  price: 0,
  properties: [],
};

// map api menu item to cart item
export function mapItemToCart(
  sku = defaultSku,
  storeCategory = {},
  attachProperties = [],
  extras = [],
  parts = [],
) {
  return {
    sku,
    storeCategory,
    attachProperties,
    extras,
    parts,
    count: 1,
  };
}

export function isCarrierBagInCart(cart) {
  return !isEmpty(cart.filter(item => item.sku.name === 'Pose'));
}

export function setCarrierBagAtLeastOne(carrierBag, carrierBagStoreCategory) {
  return {
    type: ADD_DISH,
    payload: {
      sku: carrierBag,
      storeCategory: carrierBagStoreCategory,
      attachProperties: [],
      extras: [],
      parts: [],
      count: 1,
    },
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_DISH: {
      const addTargetIndex = getCartItemIndex(state, action.payload);
      if (addTargetIndex >= 0) {
        const addNewState = cloneDeep(state);
        addNewState[addTargetIndex].count += 1;
        return addNewState;
      }
      return [...state, ...[{ ...defaultScheme, ...action.payload }]];
    }
    case ADD_MULTIPLE_DISH: {
      const addTargetIndex = getCartItemIndex(state, action.payload.dish);
      if (addTargetIndex >= 0) {
        const addNewState = cloneDeep(state);
        addNewState[addTargetIndex].count += action.payload.count;
        return addNewState;
      }
      return [
        ...state,
        ...[{
          ...defaultScheme,
          ...action.payload.dish,
          count: action.payload.count,
        }],
      ];
    }
    case REMOVE_DISH: {
      const removeTargetIndex = getCartItemIndex(state, action.payload);
      const removeNewState = cloneDeep(state);
      if (removeTargetIndex >= 0) {
        removeNewState[removeTargetIndex].count -= 1;
        if (removeNewState[removeTargetIndex].count >= get(action, 'payload.minimun', 1)) {
          return removeNewState;
        }
      }
      removeNewState.splice(removeTargetIndex, 1);
      return removeNewState;
    }
    case RESET_CART:
      return [];
    default:
      return state;
  }
};
