import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { push } from 'react-router-redux';
import { push } from 'connected-react-router';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import OrderDetailModal from 'components/presentations/OrderDetailModal';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { omit, isEmpty, get } from 'lodash';
import qs from 'qs';
import paymentCallbackKeys from 'utils/paymentCallbackKeys';
import { resetCart, simplifyTargetCartItem, getDefaultCartItem } from 'status/partial/cart';
import { resetOrderinfo } from 'status/partial/orderinfo';
import formatCurrency from 'utils/formatCurrency';
import { createWebSocket, getOrderByCode } from 'apis/services/order';
import isShowCartItemDetail from 'utils/isShowCartItemDetail';
import { timeformat } from 'utils/timeTools';
import {
  getTotalCartItemDiscount,
  totalDiscountOfCart,
  getAmountWithCoupon,
} from 'utils/getCartAmount';
import styles, { tableCellStyles } from './styles';

function stringifyOrderStatus(status, time) {
  const orderStatusMap = {
    holding: `Vi har modtaget og accepteret din ordre. Den vil være klar til den ${timeformat(time)}.`,
    waiting: 'Vi behandler denne order.',
    delivering: 'Ordren leveres.',
    succeed: 'Ordren er klar.',
    accepted: `Vi har modtaget og accepteret din ordre. Den vil være klar til den ${timeformat(time)}.`,
    closed: 'Din ordre er blevet afvist.',
  };
  if (!isEmpty(orderStatusMap[status])) {
    return orderStatusMap[status];
  }
  return status;
}

const CustomTableCell = withStyles(tableCellStyles)(TableCell);

function getCartItemKey(cartItem) {
  const target = simplifyTargetCartItem(cartItem);
  return JSON.stringify(target);
}

class BookSuccess extends React.PureComponent {
  state = {
    isOpenDetailModal: false,
    currentDish: getDefaultCartItem(),
    currentOrder: {
      delivery_amount: 0,
      ordersku_set: [],
      created: '1970-01-01T00:00:00.0Z',
    },
  }

  componentDidMount() {
    const { orderinfo, router } = this.props;
    let orderCode = qs.parse(router.location.search.slice(1)).order_code;
    if (orderCode == null) {
      orderCode = orderinfo.order_code;
    }
    this.listenNetOrder(orderCode);
    getOrderByCode({
      order_code: orderCode,
    }).then((res) => {
      this.setState({
        currentOrder: res,
      });
    });
  }

  componentWillUnmount() {
    this.clearCache();
  }

  listenNetOrder = (orderCode) => {
    const { store } = this.props;
    this.socket = createWebSocket(store.id);
    this.socket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const message = get(data, 'message', {});
      if (get(message, 'content.order_code', '') === orderCode && message.method === 'PATCH') {
        this.setState({
          currentOrder: message.content,
        });
      }
    };
  }

  back = () => {
    this.props.dispatch(push({
      pathname: '/',
    }));
    this.clearCache();
  }

  clearCache = () => {
    this.props.resetCart();
    this.props.resetOrderinfo();
    const query = omit(qs.parse(window.location.search.slice(1)), paymentCallbackKeys);
    if (Object.keys(query).length > 0) {
      window.location.search = `?${qs.stringify(query)}`;
    } else {
      window.location.search = '';
    }
  }

  openDetailModal = (cartItem) => {
    if (isShowCartItemDetail(cartItem)) {
      this.setState({
        currentDish: cartItem,
        isOpenDetailModal: true,
      });
    }
  }

  render() {
    const { classes, cart, orderinfo, promotion } = this.props;
    const { isOpenDetailModal, currentDish, currentOrder } = this.state;
    const discountedPrice = totalDiscountOfCart(cart, promotion, new Date());
    const amountWithCoupon = getAmountWithCoupon(discountedPrice, get(currentOrder, 'coupon', {}));
    return (
      <div className={classes.wrapper}>
        <Topbar />
        <div className={classes.content}>
          <div className={classes.inner}>
            <Typography className={classes.title} variant="body2" align="center" gutterBottom>Tak for din ordre, Vi sender en E-mail til dig.</Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>Order Nr. { orderinfo.order_code }</Typography>
            {
              !isEmpty(currentOrder) &&
                <Typography variant="subtitle1" align="center" gutterBottom>
                  {stringifyOrderStatus(currentOrder.status, currentOrder.plan_datetime)}
                </Typography>
            }
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>Navn</CustomTableCell>
                    <CustomTableCell align="right">Antal</CustomTableCell>
                    <CustomTableCell align="right">Prisr</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cart.map(n => (
                    <TableRow className={classes.row} key={getCartItemKey(n)}>
                      <CustomTableCell
                        component="th"
                        scope="row"
                        className={classNames({
                          [classes.more]: isShowCartItemDetail(n),
                        })}
                        onClick={() => this.openDetailModal(n)}
                      >
                        {n.sku.name}
                      </CustomTableCell>
                      <CustomTableCell align="right">{n.count}</CustomTableCell>
                      <CustomTableCell align="right">
                        {
                          formatCurrency(
                            getTotalCartItemDiscount(n, promotion, new Date()),
                          )
                        }
                      </CustomTableCell>
                    </TableRow>
                  ))}
                  {
                    !isEmpty(get(currentOrder, 'coupon', {})) &&
                      <React.Fragment>
                        <TableRow className={classes.row}>
                          <CustomTableCell component="th" scope="row">
                            Sum priser
                          </CustomTableCell>
                          <CustomTableCell align="right" />
                          <CustomTableCell align="right">
                            {
                              formatCurrency(
                                discountedPrice,
                              )
                            }
                          </CustomTableCell>
                        </TableRow>
                        <TableRow className={classes.row}>
                          <CustomTableCell component="th" scope="row">
                            Kupon
                          </CustomTableCell>
                          <CustomTableCell align="right" />
                          <CustomTableCell align="right">
                            {
                              formatCurrency(
                                0 - (discountedPrice - amountWithCoupon),
                              )
                            }
                          </CustomTableCell>
                        </TableRow>
                      </React.Fragment>
                  }
                  <TableRow className={classes.row}>
                    <CustomTableCell component="th" scope="row">
                      Lerveringgebyr
                    </CustomTableCell>
                    <CustomTableCell align="right" />
                    <CustomTableCell align="right">
                      { formatCurrency(orderinfo.delivery_amount) }
                    </CustomTableCell>
                  </TableRow>
                  {
                    currentOrder.payment_type === 'card' &&
                    <TableRow className={classes.row}>
                      <CustomTableCell component="th" scope="row">
                        Håndterings Gebyr
                      </CustomTableCell>
                      <CustomTableCell align="right" />
                      <CustomTableCell align="right">
                        { formatCurrency(1.89) }
                      </CustomTableCell>
                    </TableRow>
                  }
                  <TableRow className={classes.row}>
                    <CustomTableCell component="th" scope="row">
                      Total Priser
                    </CustomTableCell>
                    <CustomTableCell align="right" />
                    <CustomTableCell align="right">
                      {
                        currentOrder.payment_type === 'card' ?
                          formatCurrency(
                            amountWithCoupon + orderinfo.delivery_amount + 1.89,
                          )
                          :
                          formatCurrency(
                            amountWithCoupon + orderinfo.delivery_amount,
                          )
                      }
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <Button color="primary" variant="outlined" onClick={this.back} className={classes.submit}>Tilbage til</Button>
            <Typography variant="caption" align="center">Hvis e-mail ikke er modtaget, skal du kontrollere trash</Typography>
          </div>
        </div>
        <Footer />
        <OrderDetailModal
          open={isOpenDetailModal}
          dish={currentDish}
          onClose={() => this.setState({ isOpenDetailModal: false })}
          promotion={promotion}
        />
      </div>
    );
  }
}

BookSuccess.propTypes = {
  classes: PropTypes.shape().isRequired,
  orderinfo: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
  router: PropTypes.shape().isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dispatch: PropTypes.func.isRequired,
  resetCart: PropTypes.func.isRequired,
  resetOrderinfo: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    cart: state.cart,
    orderinfo: state.orderinfo,
    promotion: state.promotion,
    store: state.store,
    router: state.router,
  }),
  dispatch => ({
    ...bindActionCreators({
      resetCart,
      resetOrderinfo,
    }, dispatch),
    dispatch,
  }),
)(withStyles(styles)(BookSuccess));
