import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AccountIcon from '@material-ui/icons/AccountCircle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import className from 'classnames';
import { Link } from 'react-router-dom';
import { Field, reduxForm, propTypes, Form } from 'redux-form';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import EmailField from 'components/presentations/Fields/EmailField';
import NameField from 'components/presentations/Fields/NameField';
import PasswordField from 'components/presentations/Fields/PasswordField';
import validator from 'utils/validator';
import resetPassowrd from 'apis/services/resetPassowrd';
import { message } from 'antd';
import { isEmpty } from 'lodash';
import styles from './styles';

function Saved({ input }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value}
          onChange={input.onChange}
          color="primary"
        />
      }
      label="Forbliv logget ind"
    />
  );
}

Saved.propTypes = {
  input: PropTypes.shape().isRequired,
};

function TermLink({ store, classes }) {
  if (isEmpty(store.epay_term_href)) {
    return (
      <React.Fragment>
        <Link to={{ pathname: '/term' }} className={classes.light}> vores vilkår og betingelser </Link>
        og <Link to={{ pathname: '/term' }} className={classes.light}>privatlivspolitik</Link>.
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <a href={store.epay_term_href} target="_blank" rel="noopener noreferrer" className={classes.light}> vores vilkår og betingelser </a>
      og <a href={store.epay_term_href} target="_blank" rel="noopener noreferrer" className={classes.light}>privatlivspolitik</a>
    </React.Fragment>
  );
}

TermLink.propTypes = {
  store: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
};


class Signin extends React.PureComponent {
  state = {
    open: false,
    sending: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  sendEmail = async (values) => {
    const { store } = this.props;
    message.loading('', 30);
    this.setState({
      sending: true,
    });
    try {
      await resetPassowrd({
        email: values.username,
        diner_name: values.diner_name,
        store_id: store.id,
      });
      message.destroy();
      message.success('Email sendt');
      await this.setState({
        open: false,
        sending: false,
      });
    } catch (error) {
      message.destroy();
      message.error('Indtast venligst korrekt navn og e-mail.');
      await this.setState({
        sending: false,
      });
    }
  }

  render() {
    const { classes, handleSubmit, submitting, to, booking, values } = this.props;
    return (
      <div>
        <div className={className(classes.padding, classes.middle)}>
          <AccountIcon className={classes.title} />
        </div>
        <Form noValidate onSubmit={handleSubmit} autoComplete="off">
          <Field
            name="username"
            component={EmailField}
          />
          <Field
            name="password"
            component={PasswordField}
          />
          <div className={classes.bottom}>
            <Field
              name="saved"
              component={Saved}
            />
            <Button color="primary" className={classes.forgot} onClick={this.handleClickOpen}>Glemt din adgangskode?</Button>
          </div>
          <div className={classes.padding}>
            {
              submitting ?
                <Button color="primary" variant="contained" fullWidth disabled>
                  <CircularProgress size={20} />
                </Button>
                :
                <Button color="primary" variant="contained" fullWidth type="submit">Log ind</Button>
            }
          </div>
          <Typography className={className(classes.padding, classes.middle)}>
            Ny? <Link className={classes.light} to={{ pathname: '/signup', search: to }} >Opret Konto</Link>
          </Typography>
          <Typography className={className(classes.padding, classes.middle)}>
            Ved at oprette en konto accepterer du
            <TermLink store={this.props.store} classes={classes} />
          </Typography>
          {
            booking &&
              <Grid container spacing={5} className={classes.padding}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <Button
                    component={Link}
                    to={{
                      pathname: '/shipping',
                    }}
                    color="primary"
                    variant="contained"
                    fullWidth
                  >Gæst</Button>
                </Grid>
              </Grid>
          }
        </Form>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
        >
          <DialogTitle>Glemt din adgangskode?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Har du glemt din adgangskode?Indtast dit brugernavn (e-mail)
              , og vi sender dig code på e-mail.
            </DialogContentText>
            <Field
              name="diner_name"
              component={NameField}
            />
            <Field
              name="username"
              component={EmailField}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="default">
              Afbestille
            </Button>
            <Button onClick={() => this.sendEmail(values)} color="primary" disabled={this.state.sending}>
              Okay
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

Signin.propTypes = {
  ...propTypes,
  classes: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
  to: PropTypes.string,
  booking: PropTypes.bool,
};

Signin.defaultProps = {
  to: '',
  booking: false,
};

export default reduxForm({
  form: 'signin',
  initialValues: {
    saved: true,
  },
  validate(values) {
    const result = {};
    if (!validator.isPassword(values.password)) {
      result.password = 'Adgangskode længde større end eller lig med ';
    }
    if (!validator.isEmail(values.username)) {
      result.username = 'Indtast venligst den rigtige email';
    }
    return result;
  },
})(connect(state => ({
  values: state.form.signin.values,
  store: state.store,
}))(withStyles(styles)(Signin)));
