import { get } from 'lodash';
import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;

export default (params) => {
  const id = get(params, 'id', 0);
  return request.patch(`${apiUrlV1}/diner/${id}/`, params);
};

