import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import Footer from 'components/presentations/Footer';

function getName(store) {
  const netPayment = get(store, 'net_pay_type_currently', 'none');
  const merchantNumber = get(store, 'merchant_number', '');
  if (netPayment === 'epay' && merchantNumber !== '1030657' && !isEmpty(merchantNumber)) {
    return store.name;
  }
  if (netPayment === 'scanpay') {
    return store.name;
  }
  return 'E-Screen';
}

class FooterWrapper extends React.PureComponent {
  render() {
    const { store } = this.props;
    const name = getName(store);

    return (
      <Footer
        email={store.main_email}
        address={`${store.street_name} ${store.house_number} ${store.floor_number}, ${store.post_number} ${store.city_name}`}
        cellphone={store.main_phone_number}
        name={name}
      />
    );
  }
}

FooterWrapper.propTypes = {
  store: PropTypes.shape().isRequired,
};

export default connect(
  state => ({
    store: state.store,
  }),
)(FooterWrapper);
