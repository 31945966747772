import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import classeNames from 'classnames';
import formatCurrency from 'utils/formatCurrency';
import { totalDiscountOfCart } from 'utils/getCartAmount';
import styles from './styles.js';

const CartBottomBar = props => (
  <ListItem
    className={classeNames(props.classes.listItem, props.classes.wrapper)}
    onClick={props.onClick}
  >
    <ListItemText primary="Se kurv" />
    <ListItemIcon className={props.classes.iconWrap}>
      <ShoppingCartIcon />
    </ListItemIcon>
    <ListItemText
      primary={`DKK ${formatCurrency(totalDiscountOfCart(props.cart, props.promotion, new Date()))}`}
    />
    <Badge
      badgeContent={props.cart.reduce((count, item) => count + item.count, 0)}
      color="secondary"
      className={props.classes.badge}
    >
      <span />
    </Badge>
  </ListItem>
);

CartBottomBar.propTypes = {
  classes: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
  cart: PropTypes.arrayOf(PropTypes.shape()),
  promotion: PropTypes.arrayOf(PropTypes.shape()),
};

CartBottomBar.defaultProps = {
  open: true,
  onClick: () => null,
  cart: [],
  promotion: [],
};

export default withStyles(styles)(CartBottomBar);
