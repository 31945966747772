import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;

export function addDiner(params) {
  return request.post(`${apiUrlV1}/diner/`, params);
}

export function signup(params) {
  return request.post(`${apiUrlV1}/registration/`, params);
}
