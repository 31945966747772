
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CookieConsent from 'react-cookie-consent';
import { colors } from 'themes/variables';
import styles from './styles';

class CookieConsentBar extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <CookieConsent
        buttonText="Acceptér"
        buttonStyle={{ background: colors.primary }}
        expires={180}
      >
        Ved at benytte denne hjemmeside, accepterer du, at vi bruger cookies. <Link to={{ pathname: '/term' }} className={classes.light}> Læs Mere </Link>
      </CookieConsent>
    );
  }
}

CookieConsentBar.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(CookieConsentBar);
