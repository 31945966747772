/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';

import styles from './styles';

const Term = (props) => (
  <div className={props.classes.wrapper}>
    <Topbar />
      <div className={props.classes.content}>
      <p>Last Updated 1 Jan 2020</p>

      <h1>Website Terms and Conditions</h1>

      <ul>
      <li>I. Terms and Conditions</li>
      <li>II. Privacy Policy</li>
      </ul>

      <p>This page sets out the terms and conditions (&quot;<strong>Website Terms</strong>&quot;) on which we, E-Screen IVS (&quot;<strong>we</strong>&quot;), provide our services through our website <a href="http://www.nonoco.dk">http://www.nonoco.dk</a>, which you access, our website or services (&quot;<strong>Website</strong>&quot;). Please read these Website Terms carefolly before ordering any products through, the Website, as your purchase of any products offered on the Website is subject to these Website Terms. By ordering products via the Website (whether now or in the future), you agree to be bound by these Website Terms. Use of the Website is also subject to these Website Terms.</p>

      <p>Use of your personal information submitted via the Website is governed by our <a href="#">Privacy Policy</a>. By accessing any part of the Website, you indicate that you accept these Website Terms. If you do not accept these Website Terms, you shoold leave the Website immediately, and you will not be able to order any products through the Website.</p>

      <h2>I.TERMS AND CONDITIONS</h2>

      <h3>I.INTRODUCTION AND OUR ROLE</h3>

      <ol>
        <li><strong>Company details</strong>: E-Screen IVS er registreret i Danmark med CVR-nr. 38 65 56 04, Industriholmen 82,2650 Hvidovre,Denmark.</li>
        <li><strong>Service</strong>: We provide a way for you to communicate your orders (&quot;<strong>Orders</strong>&quot;) for products (&quot;<strong>Products</strong>&quot;) displayed on the Website (the &quot;<strong>Service</strong>&quot;).</li>
      </ol>

      <h3>II.ACCESS AND TERMS</h3>

      <ol>
        <li><strong>Acceptance of terms</strong>: By accessing any part of the Website, you indicate that you accept these Website Terms. If you do not accept these Website Terms, you shoold leave the Website immediately, and you will not be able to order any Products through the Website.</li>
        <li><strong>Revision of terms</strong>: We may revise these Website Terms at any time. You shoold check the Website regolarly to review the current Website Terms, because they are binding on you. You will be subject to the policies, terms, and conditions in force at the time that you place an Order through us.</li>
      </ol>

      <h3>III.YOUR STATUS</h3>

      <ol>
        <li><strong>Capacity and age</strong>: By placing an Order through the Website, you warrant that:
      You are legally capable of entering into binding contracts;
      You are at least 18 years old.</li>
        <li><strong>Alcohol and cigarettes</strong>: Furthermore, you acknowledge and agree that:
          <ol>
            <li>Pursuant to the Licensing Act 1964, it is an offence for any person under the age of 18 to buy, or attempt to buy, intoxicating liquor, or for any person over the age of 18 to buy intoxicating liquor on behalf of any person under the age of 18;</li>
            <li>Cigarettes are not for sale to persons under the age of 18;</li>
            <li>Orders containing either alcohol or cigarettes can therefore not be accepted from or on behalf of persons under the age of 18.</li>
          </ol>
        </li>
      </ol>

      <h3>IV.ORDER</h3>

      <ol>
        <li><strong>Compiling your Order</strong>: Once you have selected the Products you wish to order from the menu and provided the other required information, you will be given the opportunity to submit your Order. It is important that you check all the information that you enter and correct any errors, once you do so we will start processing your Order and errors cannot be corrected.</li>
        <li><strong>Amending or cancelling your Order</strong>: Once you have submitted your Order and your payment has been authorized, you will not be entitled to change or cancel your Order, nor will you be entitled to a refund (please refer to paragraph 4.4 for details of the process relating to rejected Orders). If you wish to change or cancel your Order, you may contact the Restaurant in order to communicate your requests.</li>
        <li><strong>Payment authorization</strong>: Where any payment you make is not authorized, your Order will not be processed or communicated to the Restaurant. When the restaurant accepts the order, the payment will be deducted.</li>
        <li><strong>Processing your Order</strong>: On receipt of your Order, we will begin processing it by sending it to the Restaurant and will notify you by email that your Order has been received and is being processed or rejected.Please note that Restaurants have the discretion to reject Orders at any time because they are too busy, due to weather conditions or for any other reason.</li>
        <li><strong>Delivery of your Order</strong>: Estimated times for deliveries and collections are provided by the Restaurants and are only estimates. Neither we nor the Restaurants guarantee that Orders will be delivered or will be available for collection within the estimated times.</li>
        <li><strong>Legislation on alcohol</strong>:E-Screen complies with Danish legislation on the sale of alcohol. Therefore, if you are under 18 years of age, you cannot buy alcohol above 16.5%. If you are 16-18 years old, you can buy alcoholic goods up to 16.5%.</li>
      </ol>

      <h3>V.PRICE AND PAYMENT</h3>

      <ol>
        <li><strong>VAT and delivery costs</strong>: Prices will be as quoted on the Website. These prices include VAT but may exclude delivery costs (if you opt for delivery instead of collection) and any online payment administration charge imposed by the Restaurant (if you pay for your Order online). These will be added to the total amount due where applicable.</li>
        <li><strong>Payment methods</strong>: Payment for Orders must be made by an accepted credit or debit card through the Website or in cash to the Restaurant at the point of delivery to you.</li>
        <li><strong>Rejected Orders</strong>: Because of standard banking procedures, once you have submitted an Order that you are paying for by credit or debit card and your payment has been authorized, your bank or card issuer will &quot;ring-fence&quot; the foll amount of your Order. If your Order is subsequently rejected by the Restaurant (as described in paragraph 4.4 above) or cancelled for any other reason, your bank or card issuer will not transfer the funds for the Order to us, and will instead release the relevant amount back into your available balance. However, this may take between 3 to 5 working days (or longer, depending on your bank or card issuer). You acknowledge and agree that neither we nor the relevant Restaurant will be responsible or liable to you in relation to this delay by your bank or card issuer in the release of funds back into your account.</li>
      </ol>

      <h3>VI.SERVICE ACCESS</h3>

      <ol>
        <li><strong>Website availability</strong>: While we try to ensure the Website is normally available all day, we do not undertake any obligation to do so, and we will not be liable to you if the Website is unavailable at any time or for any period.</li>
        <li><strong>Information security</strong>: The transmission of information via the internet is not completely secure. Although we take the steps required by law to protect your information, we cannot guarantee the security of your data transmitted to the Website; any transmission is at your own risk.</li>
      </ol>

      <h2>II. PRIVACY POLICY</h2>

      <p>We are committed to protecting the privacy of our users. This Privacy Policy will apply to use of the Service in whatever context, whether by mobile digital routes including (but not limited to) mobile, tablet or other devices/technology, or by Your PC or laptop (“Devices”). Please read the following Privacy Policy, which explains how we use and protect your information.</p>

      <h3>I.INFORMATION THAT WE COLLECT FROM YOU</h3>

      <ol>
        <li>When you visit the Website or use the Service to make an Order from a Restaurant through the Website, you may be asked to provide information about yourself including your name, contact details (such as telephone and mobile numbers and e-mail address) and payment information (such as credit or debit card information).</li>
        <li>By accessing the Website or Service using mobile digital routes such as (but not limited to) mobile, tablet or other devices/technology, then you should expect that our data collection and usage as set out in this Privacy Policy will apply in that context too.</li>
        <li>Who we share personal information with Restaurants that you have placed an Order with for processing and delivery; Third parties who support our Services (e.g. partners for marketing or promotions in the food, drink and leisure sectors based on your preferences); Any law enforcement or regulatory body, government agency, court or other third party where we believe disclosure is necessary under applicable law or regulations; Any other person provided that you have given your consent. We will never sell, distribute or otherwise share your personal information unless we have your permission.</li>
      </ol>

      <h3>II.DISCLOSURE OF YOUR INFORMATION</h3>

      <ol>
        <li>The information you provide to us will be transferred to and stored on our servers. There may be circumstances where E-Screen is obliged to disclosure your personal information, for example, where it is under a legal obligation to disclose such information, or to enforce or apply its user terms and conditions, and other agreements.</li>
        <li>The third parties with whom we share your information may undertake various activities such as processing credit card payments and providing support services for us. In addition, we may need to provide your information to any Restaurants that you have placed an Order with to allow the Restaurant to process and deliver your Order. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.</li>
        <li>If our business enters into a joint venture with, purchases or is sold to or merged with another business entity, your information may be disclosed or transferred to the target company, our new business partners or owners or their advisors.</li>
      </ol>

      <h3>III.SECURITY AND DATA RETENTION</h3>

      <ol>
        <li>We take steps to protect your information from unauthorized access and against unlawful processing, accidental loss, destruction and damage. We will keep your information for a reasonable period or as long as the law requires.</li>
        <li>Where you have chosen a password, which allows you to access certain parts of the Website, you are responsible for keeping this password confidential. Unless we negligently disclose your password to a third party, we will not be liable for any unauthorized transactions entered into using your name and password.</li>
        <li>The transmission of information via the internet is not completely secure. Although we will take steps to protect your information, we cannot guarantee the security of your data transmitted to the Website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.</li>
      </ol>

      <h3>IV.CONTACT</h3>

      <ol>
        <li>All comments, queries and requests relating to our use of your information are welcomed by telephone to +45 53 53 65 07.</li>
      </ol>

      <p>
        <br />
      </p>
    </div>
    <Footer />
  </div>
);

Term.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(Term);
