import { isEmpty, get } from 'lodash';
import { findTargetPromotion } from 'utils/promotionTools';

export function getUnitCartItemAmount(skuItem) {
  const { sku, extras } = skuItem;
  const extraTotalPrice = extras.reduce(
    (total, item) => total + (item.extra.price * item.count),
    0,
  );
  const totalPrice = sku.price + extraTotalPrice;
  return totalPrice;
}

export function getTotalCartItemAmount(cartItem) {
  return getUnitCartItemAmount(cartItem) * cartItem.count;
}

export function totalAmountOfCart(cart) {
  return cart.reduce(
    (totalAmount, item) => getTotalCartItemAmount(item) + totalAmount,
    0,
  );
}

export function getUnitCartItemDiscount(skuItem, promotion, targetDatetime) {
  const { sku, extras } = skuItem;
  const extraTotalPrice = extras.reduce(
    (total, item) => total + (item.extra.price * item.count),
    0,
  );
  const targetPromotion = findTargetPromotion(
    sku.merchandise,
    promotion,
    targetDatetime,
  );
  let skuPrice = sku.price;
  if (!isEmpty(targetPromotion)) {
    skuPrice = sku.price * targetPromotion.detail.value;
  }
  const totalPrice = skuPrice + extraTotalPrice;
  return totalPrice;
}
export function getTotalCartItemDiscount(cartItem, promotion, targetDatetime) {
  return getUnitCartItemDiscount(cartItem, promotion, targetDatetime) * cartItem.count;
}

export function totalDiscountOfCart(cart, promotion, targetDatetime) {
  return cart.reduce(
    (totalDiscount, item) => getTotalCartItemDiscount(
      item,
      promotion,
      targetDatetime,
    ) + totalDiscount,
    0,
  );
}

export function getAmountWithCoupon(originAmount, coupon) {
  switch (get(coupon, 'coupon_promotion.promotion_type', '')) {
    case 'discount':
      return Math.round(originAmount * get(coupon, 'coupon_promotion.detail.value', 1) * 100) / 100;
    case 'set_price':
      return get(coupon, 'coupon_promotion.detail.value', originAmount);
    case 'reduce_price':
      return originAmount - get(coupon, 'coupon_promotion.detail.value', 0);
    case 'per_reduce_price': {
      const level = parseInt(originAmount / get(coupon, 'coupon_promotion.effective_amount', originAmount + 1), 10);
      return originAmount - (get(coupon, 'coupon_promotion.detail.value', 0) * level);
    }
    default:
      return originAmount;
  }
}
