import getDinerAddressList from 'apis/services/addresses';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

const FETCH_ADDRESSES = Symbol('FETCH_ADDRESSES');
const FETCH_ADDRESSES_SUCCESS = Symbol('FETCH_ADDRESSES_SUCCESS');

export const actionTypes = {
  FETCH_ADDRESSES,
  FETCH_ADDRESSES_SUCCESS,
};

export const initialState = [];

export const fetchAddresses = params => ({ type: FETCH_ADDRESSES, payload: params });

export const fetchAddressesSuccess = response => ({
  type: FETCH_ADDRESSES_SUCCESS,
  payload: response,
});

export const fetchAddressesEpic = action$ =>
  action$.ofType(FETCH_ADDRESSES).mergeMap(action =>
    Observable.fromPromise(getDinerAddressList(action.payload))
      .map(response => fetchAddressesSuccess(response)),
  );

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADDRESSES_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
};
