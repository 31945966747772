import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import moment from 'moment';
import { isEmpty, get } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

import { fetchCoupon } from 'status/partial/coupon';
import styles from './styles';

class Coupon extends React.PureComponent {
  state = {
    sending: false,
    openDetail: false,
    currentCoupon: {},
  }

  componentDidMount() {
    const { auth } = this.props;
    this.props.fetchCoupon({
      contact_email: auth.user.email,
    });
  }

  render() {
    const { classes, coupon } = this.props;
    const { openDetail, sending, currentCoupon } = this.state;
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>Coupon</Typography>
        <Divider />
        <br />
        <div className={classes.wrap}>
          {
            coupon.map(item => (
              <div
                className={classes.cardWrap}
                key={item.id}
              >
                <Card
                  className={classes.card}
                  onClick={() => this.setState({
                    openDetail: true,
                    currentCoupon: item,
                  })}
                >
                  <CardContent>
                    <Typography gutterBottom variant="headline" component="h2" align="center">
                      { item.coupon_promotion.store.name }
                    </Typography>
                    <Typography component="p" className={classes.textCenter} gutterBottom>
                      <QRCode value={item.id} size={128} />
                    </Typography>
                    <Typography component="p" gutterBottom align="center">
                      { item.id }
                    </Typography>
                    <Typography component="p" align="center">
                      { `${moment(item.coupon_promotion.start_time).format('YYYY/MM/DD')}` }
                      { ' - ' }
                      { `${moment(item.coupon_promotion.end_time).format('YYYY/MM/DD')}` }
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))
          }
        </div>

        <Dialog
          open={openDetail}
          onClose={() => this.setState({
            openDetail: false,
            currentCoupon: {},
          })}
        >
          <DialogTitle className={classes.textCenter}>Coupon Details</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.textCenter}>
              {
                !isEmpty(currentCoupon.id) &&
                  <QRCode value={currentCoupon.id} size={256} />
              }
            </DialogContentText>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Name:
                  </TableCell>
                  <TableCell>
                    {get(currentCoupon, 'coupon_promotion.name', '')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Descrption:
                  </TableCell>
                  <TableCell>
                    {get(currentCoupon, 'coupon_promotion.description', '')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Start Date:
                  </TableCell>
                  <TableCell>
                    { `${moment(get(currentCoupon, 'coupon_promotion.start_time', new Date())).format('YYYY/MM/DD')}` }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    End Date:
                  </TableCell>
                  <TableCell>
                    { `${moment(get(currentCoupon, 'coupon_promotion.end_time', new Date())).format('YYYY/MM/DD')}` }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Type:
                  </TableCell>
                  <TableCell>
                    { get(currentCoupon, 'coupon_promotion.promotion_type', '') }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Value:
                  </TableCell>
                  <TableCell>
                    { get(currentCoupon, 'coupon_promotion.detail.value', '') }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({
                openDetail: false,
                currentCoupon: {},
              })}
              color="primary"
              disabled={sending}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    );
  }
}

Coupon.propTypes = {
  classes: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
  coupon: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fetchCoupon: PropTypes.func.isRequired,
};


export default connect(state => ({
  auth: state.auth,
  store: state.store,
  coupon: state.coupon,
}), dispatch => ({
  dispatch,
  fetchCoupon: bindActionCreators(fetchCoupon, dispatch),
}))(withStyles(styles)(Coupon));
