import React from 'react';
import PropTypes from 'prop-types';
import { omit, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';

import { message } from 'antd';
import { createAddress, deleteAddress, patchAddress } from 'apis/services/addresses';
import { fetchAddresses } from 'status/partial/addresses';
import AddressForm from 'components/containers/forms/Address';
import styles from './styles';

class UserInfomation extends React.PureComponent {
  state = {
    openDeleteCard: false,
    openEditCard: false,
    sending: false,
    currentAddress: {},
  }

  componentDidMount() {
    const { diner } = this.props;
    this.props.fetchAddresses({
      diner: diner.id,
    });
  }

  editSubmit = async (values) => {
    const { currentAddress } = this.state;
    if (isEmpty(currentAddress)) {
      await this.createAddress(values);
    } else {
      await this.updateAddress(values);
    }
  }

  updateAddress = async (values) => {
    const { diner } = this.props;
    const { currentAddress } = this.state;
    try {
      message.loading('', 30);
      await patchAddress({
        ...values,
        ...{
          diner: diner.id,
          country: 1,
        },
      }, currentAddress.id);
      this.props.fetchAddresses({
        diner: diner.id,
      });
      message.destroy();
      message.success('Vellykket drift.');
      this.setState({
        openEditCard: false,
      });
    } catch (error) {
      message.destroy();
      message.error('Betjening mislykkedes.');
    }
  }
  createAddress = async (values) => {
    const { diner } = this.props;
    try {
      message.loading('', 30);
      await createAddress({
        ...values,
        ...{
          diner: diner.id,
          country: 1,
        },
      });
      this.props.fetchAddresses({
        diner: diner.id,
      });
      message.destroy();
      message.success('Vellykket drift.');
      this.setState({
        openEditCard: false,
      });
    } catch (error) {
      message.destroy();
      message.error('Betjening mislykkedes.');
    }
  }

  deleteAddress = async () => {
    const { currentAddress } = this.state;
    const { diner } = this.props;
    try {
      message.loading('', 30);
      await deleteAddress(currentAddress.id);
      this.props.fetchAddresses({
        diner: diner.id,
      });
      message.destroy();
      message.success('Vellykket drift.');
      this.setState({
        openDeleteCard: false,
        currentAddress: {},
      });
    } catch (error) {
      message.destroy();
      message.error('Betjening mislykkedes.');
    }
  }

  render() {
    const { classes, addresses } = this.props;
    const { openDeleteCard, openEditCard, sending, currentAddress } = this.state;
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>Leverings adresse</Typography>
        <Typography variant="body1" gutterBottom align="right">
          <Button
            className={classes.light}
            variant="flat"
            color="primary"
            onClick={() => this.setState({ openEditCard: true })}
          >
            <AddIcon className={classes.leftIcon} />
            Tilføj adresse
          </Button>
        </Typography>
        <Divider />
        <br />
        <div className={classes.cardWrap}>
          {
            addresses.map(item => (
              <Card className={classes.card} key={item.id}>
                <CardContent>
                  <Typography gutterBottom variant="headline" component="h2">
                    { item.name }
                  </Typography>
                  <Typography component="p">
                    {'Adresse: '}
                    { item.street_name }
                    {' '}
                    { item.house_number }
                    {' '}
                    { item.floor_number }
                    {', '}
                    { item.post_number }
                    {' '}
                    { item.city_name }
                  </Typography>
                  <Typography component="p">
                    { `Navn: ${item.contact_name}` }
                  </Typography>
                  <Typography component="p">
                    { `Tlf: ${item.contact_phone}` }
                  </Typography>
                  <Typography component="p">
                    { `Email: ${item.contact_email}` }
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="flat"
                    color="secondary"
                    size="small"
                    className={classes.button}
                    onClick={() => this.setState({
                      openDeleteCard: true,
                      currentAddress: item,
                    })}
                  >
                    <DeleteIcon className={classes.leftIcon} />
                    Slet
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => this.setState({
                      openEditCard: true,
                      currentAddress: item,
                    })}
                  >
                    <EditIcon className={classes.leftIcon} />
                    Redigere
                  </Button>
                </CardActions>
              </Card>
            ))
          }
        </div>

        <Dialog
          open={openDeleteCard}
          onClose={() => this.setState({
            openDeleteCard: false,
            currentAddress: {},
          })}
        >
          <DialogTitle>Driftsbekræftelse</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Du vil slette denne adresse, er du sikker?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({
                openDeleteCard: false,
                currentAddress: {},
              })}
              color="default"
            >
              Afbestille
            </Button>
            <Button
              onClick={this.deleteAddress}
              color="primary"
              disabled={sending}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openEditCard}
          onClose={() => this.setState({ openEditCard: false })}
        >
          <DialogTitle>Tilføj adresse</DialogTitle>
          <DialogContent>
            <AddressForm
              onSubmit={values => this.editSubmit(values)}
              initialValues={{
                ...omit(
                  currentAddress,
                  ['id', 'diner', 'country'],
                ),
              }}
            />
          </DialogContent>
        </Dialog>

      </React.Fragment>
    );
  }
}

UserInfomation.propTypes = {
  classes: PropTypes.shape().isRequired,
  // auth: PropTypes.shape().isRequired,
  diner: PropTypes.shape().isRequired,
  addresses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fetchAddresses: PropTypes.func.isRequired,
};


export default connect(state => ({
  // auth: state.auth,
  diner: state.diner,
  store: state.store,
  addresses: state.addresses,
}), dispatch => ({
  dispatch,
  fetchAddresses: bindActionCreators(fetchAddresses, dispatch),
}))(withStyles(styles)(UserInfomation));
