import amber from '@material-ui/core/colors/amber';
import { colors } from 'themes/variables';

export default theme => ({
  head: theme.mixins.toolbar,
  active: {
    '& span': {
      color: amber[600],
    },
  },
  item: {
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: colors.undertone,
    },
  },
});
