import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import formatCurrency from 'utils/formatCurrency';

import { isEmpty } from 'lodash';
import styles from './styles';

class MenuCard extends React.PureComponent {
  state = {
    open: false,
  }

  onAdd = () => {
    this.props.onAdd(this.props.item);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, title, price, pic, desc, more, discount } = this.props;
    return (
      <Card className={classes.card}>
        <div className={classes.content}>
          {
            pic &&
              <CardMedia
                className={classes.media}
                image={pic}
                title={title}
                onClick={this.handleClickOpen}
              />
          }
          <CardContent className={classes.contentInner}>
            <CardHeader
              action={
                <React.Fragment>
                  {
                    more ?
                      <IconButton onClick={this.onAdd} className={classes.action}>
                        <AddCircleIcon className={classes.addIconMore} />
                      </IconButton>
                      :
                      <IconButton onClick={this.onAdd} className={classes.action}>
                        <AddCircleIcon className={classes.addIcon} />
                      </IconButton>
                  }
                </React.Fragment>
              }
              title={title}
              classes={{ root: classes.header, title: classes.headerTitle }}
            />
            <Typography component="p" className={classes.desc}>
              {desc}
            </Typography>
            <div className={classes.priceWrapper}>
              {
                !isEmpty(discount) ?
                  <React.Fragment>
                    <Typography variant="body1" className={classes.originPrice}>
                      <del>DKK {formatCurrency(price)} kr</del>
                    </Typography>
                    <Typography variant="subtitle1" className={classes.price}>
                      {
                        more &&
                          <span>Fra </span>
                      }
                      <span>DKK {formatCurrency(price * discount.detail.value)} kr</span>
                    </Typography>
                  </React.Fragment>
                  :
                  <Typography variant="subtitle1" className={classes.price}>
                    {
                      more &&
                        <span>Fra </span>
                    }
                    <span>DKK {formatCurrency(price)} kr</span>
                  </Typography>
              }
            </div>
          </CardContent>
        </div>

        {
          pic &&
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
            >
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <img src={pic} alt={title} className={classes.modalImage} />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Luk
                </Button>
              </DialogActions>
            </Dialog>
        }
      </Card>
    );
  }
}

MenuCard.propTypes = {
  classes: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
  onAdd: PropTypes.func,
  title: PropTypes.string,
  price: PropTypes.number,
  desc: PropTypes.string,
  pic: PropTypes.string,
  more: PropTypes.bool,
  discount: PropTypes.shape(),
};

MenuCard.defaultProps = {
  onAdd: () => null,
  title: '',
  desc: '',
  price: -1,
  pic: '',
  more: false,
  discount: {},
};

export default withStyles(styles)(MenuCard);
