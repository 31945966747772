import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;

export default function fetchMerchandisePromotion(originParams) {
  const params = {
    ...originParams,
    ...{
      page_size: 1000,
      promotion_type: 'discount',
    },
  };
  return request.get(`${apiUrlV1}/promotion/merchandise/`, { params }).then(res => res.data.results);
}

export function fetchCouponPromotion(originParams) {
  const params = {
    ...originParams,
    ...{
      page_size: 1000,
    },
  };
  return request.get(`${apiUrlV1}/promotion/coupon/`, { params }).then(res => res.data.results);
}
