
export default theme => ({
  card: {
    maxWidth: 345,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
});
