import React from 'react';
import { connect } from 'react-redux';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import SetPassword from 'components/containers/forms/SetPassword';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { modifyPassowrd } from 'apis/services/resetPassowrd';
import { message } from 'antd';
// import { push } from 'react-router-redux';
import { push } from 'connected-react-router';
import { resetAuth } from 'status/partial/auth';
import { resetDiner } from 'status/partial/diner';
import styles from './styles';

class Password extends React.PureComponent {
  async submit(values) {
    try {
      await modifyPassowrd(values);
      this.props.dispatch(push({
        pathname: '/signin',
      }));
      this.props.dispatch(resetAuth());
      this.props.dispatch(resetDiner());
      message.success('Dit kodeord er blevet ændret.');
    } catch (error) {
      message.error('Indtast venligst korrekt adgangskode og email.');
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.wrapper}>
        <Topbar />
        <div className={classes.content}>
          <div className={classes.formWrapper}>
            <SetPassword
              onSubmit={values => this.submit(values)}
            />
          </div>
        </div>
        <Footer />
      </main>
    );
  }
}


Password.propTypes = {
  classes: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  dispatch => ({
    dispatch,
  }),
)(withStyles(styles)(Password));
