
import { monochrome, footerHeight, colors } from 'themes/variables';
import { wrapper, mediaLarge } from 'themes/mixins';

export default theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    ...wrapper(),
  },
  content: {
    minHeight: `calc(100vh - ${footerHeight}px)`,
    backgroundColor: monochrome.white,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(2),
    marginTop: 56 + (theme.spacing(2)),
  },
  title: theme.mixins.toolbar,
  formWrapper: {
    width: '100%',
    alignSelf: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  couponContainer: {
    // alignItems: 'center',
    flexWrap: 'wrap',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  price: {
    textAlign: 'right',
    padding: 0,
  },
  light: {
    color: colors.focus,
  },
  orderTitle: {
    backgroundColor: monochrome.white,
    // color: colors.dark,
  },
  originPrice: {
    marginRight: theme.spacing(),
    color: colors.secondary,
  },
  more: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  cardWrap: {
    padding: theme.spacing(),
    width: '100%',
  },
  card: {
    cursor: 'pointer',
    position: 'relative',
    width: '100%',
  },
  couponType: {
    color: monochrome.white,
    backgroundColor: colors.focus,
  },
  couponIcon: {
    height: theme.spacing(10),
    width: theme.spacing(7),
    color: colors.focus,
    position: 'relative',
    top: theme.spacing(),
    marginLeft: theme.spacing(2),
  },
  couponSelectedIcon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    color: colors.focus,
    position: 'absolute',
    bottom: theme.spacing(),
    right: theme.spacing() / 2,
  },
  ...mediaLarge({
    cardWrap: {
      width: '50%',
    },
  }),
});
