import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1, wsApiurlV1 } = urls;

export const paidCallbackUrl = `${apiUrlV1}/net_order/paid_callback/`;

export function newOrder(params) {
  return request.post(`${apiUrlV1}/net_order/`, params);
}

export function getOrderByCode(params) {
  return request.get(`${apiUrlV1}/net_order/`, { params }).then(res => res.data.results[0]);
}

export function createWebSocket(store) {
  return new WebSocket(`${wsApiurlV1}/net_order/${store}/`);
}

export function getOrderList(params) {
  return request.get(`${apiUrlV1}/net_order/`, { params });
}
