import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import { navigatorListStyle as styles } from './styles.js';

const UserCenterNavigation = props => (
  <React.Fragment>
    <List component="nav">
      <ListItem
        className={
          classNames({
            [props.classes.item]: true,
          })
        }
        component={linkProps => (
          <NavLink
            to={`/user/infomation/${props.auth.user.pk}`}
            activeClassName={props.classes.active}
            {...linkProps}
          >
            { linkProps.children }
          </NavLink>
        )}
        button
      >
        <ListItemText>
          <span>
            Brugerinformation
          </span>
        </ListItemText>
      </ListItem>

      <ListItem
        className={
          classNames({
            [props.classes.item]: true,
          })
        }
        component={linkProps => (
          <NavLink
            to={`/user/order/${props.auth.user.pk}`}
            activeClassName={props.classes.active}
            {...linkProps}
          >
            { linkProps.children }
          </NavLink>
        )}
        button
      >
        <ListItemText>
          <span>
            Ordre
          </span>
        </ListItemText>
      </ListItem>
      {/*
      <ListItem
        className={
          classNames({
            [props.classes.item]: true,
          })
        }
        component={linkProps => (
          <NavLink
            to={`/user/configuration/${props.auth.user.pk}`}
            activeClassName={props.classes.active}
            {...linkProps}
          >
            { linkProps.children }
          </NavLink>
        )}
        button
      >
        <ListItemText>
          <span>
            Konfiguration
          </span>
        </ListItemText>
      </ListItem>
      */}
      <ListItem
        className={
          classNames({
            [props.classes.item]: true,
          })
        }
        component={linkProps => (
          <NavLink
            to={`/user/address/${props.auth.user.pk}`}
            activeClassName={props.classes.active}
            {...linkProps}
          >
            { linkProps.children }
          </NavLink>
        )}
        button
      >
        <ListItemText>
          <span>
            Leverings adresse
          </span>
        </ListItemText>
      </ListItem>
      <ListItem
        className={
          classNames({
            [props.classes.item]: true,
          })
        }
        component={linkProps => (
          <NavLink
            to={`/user/coupon/${props.auth.user.pk}`}
            activeClassName={props.classes.active}
            {...linkProps}
          >
            { linkProps.children }
          </NavLink>
        )}
        button
      >
        <ListItemText>
          <span>
            Coupon
          </span>
        </ListItemText>
      </ListItem>
    </List>
  </React.Fragment>
);

UserCenterNavigation.propTypes = {
  classes: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
};

export default withStyles(styles)(UserCenterNavigation);
