import getDeliveryTemplate from 'apis/services/delivery';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

const FETCH_DELIVERY = Symbol('FETCH_DELIVERY');
const FETCH_DELIVERY_SUCCESS = Symbol('FETCH_DELIVERY_SUCCESS');

export const actionTypes = {
  FETCH_DELIVERY,
  FETCH_DELIVERY_SUCCESS,
};

export const initialState = {};

export const fetchDeliveryTemplate = params => ({ type: FETCH_DELIVERY, payload: params });

export const fetchDeliveryTemplateSuccess = response => ({
  type: FETCH_DELIVERY_SUCCESS,
  payload: response,
});

export const fetchDeliveryTemplateEpic = (action$, store$) =>
  action$.ofType(FETCH_DELIVERY).mergeMap((action) => {
    const deliveryTemplate = store$.value.delivery;
    if (isEmpty(deliveryTemplate)) {
      return Observable.fromPromise(getDeliveryTemplate(action.payload))
        .map(response => fetchDeliveryTemplateSuccess(response));
    }
    return Observable.of(fetchDeliveryTemplateSuccess(deliveryTemplate));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DELIVERY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
