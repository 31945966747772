import getCategory from 'apis/services/category';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

const FETCH_CATEGORY = Symbol('FETCH_CATEGORY');
const FETCH_CATEGORY_SUCCESS = Symbol('FETCH_CATEGORY_SUCCESS');

export const initialState = [];

export const fetchCategory = params => ({ type: FETCH_CATEGORY, payload: params });
export const fetchCategorySuccess = response => ({
  type: FETCH_CATEGORY_SUCCESS,
  payload: response,
});

export const fetchCategoryEpic = (action$, store$) =>
  action$.ofType(FETCH_CATEGORY).mergeMap((action) => {
    const category = store$.value.category;
    if (isEmpty(category)) {
      return Observable.fromPromise(getCategory(action.payload))
        .map(response => fetchCategorySuccess(response));
    }
    return Observable.of(fetchCategorySuccess(category));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
};
