import { mediaLarge } from 'themes/mixins';

export default () => ({
  body: {
    maxHeight: '85vh',
  },
  ...mediaLarge({
    wrapper: {
      display: 'none',
    },
  }),
});
