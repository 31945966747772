import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CartContent from 'components/presentations/CartContent';
import { omit } from 'lodash';
import styles from './styles.js';

const PurchaseDrawer = (props) => {
  const { classes } = props;

  return (
    <Drawer
      open={props.open}
      onClose={props.onClose}
      className={classes.wrapper}
      anchor="bottom"
      classes={{
        paperAnchorBottom: classes.body,
      }}
    >
      <CartContent {...omit(props, ['classes'])} />
    </Drawer>
  );
};

PurchaseDrawer.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  deliveryAllow: PropTypes.bool,
  setOrderTypeToDelivery: PropTypes.func.isRequired,
  setOrderTypeToPickUp: PropTypes.func.isRequired,
  orderinfo: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

PurchaseDrawer.defaultProps = {
  onClose: () => null,
  onSubmit: () => null,
  open: true,
  deliveryAllow: true,
};

export default withStyles(styles)(PurchaseDrawer);
