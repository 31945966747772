import { colors } from 'themes/variables';
import { mediaLarge } from 'themes/mixins';

export default theme => ({
  root: {
    flexGrow: 1,
    zIndex: 12,
  },

  logoWrapper: {
    boxSizing: 'border-box',
    textDecoration: 'none',
    color: colors.dark,
    display: 'inline-block',
    marginLeft: theme.spacing(),
  },

  logo: {
    height: 40,
    maxWidth: '100%',
  },

  menuButton: {
    marginLeft: -12,
  },

  cartButton: {
    marginRight: -12,
    color: colors.primary,
  },

  cartIcon: {
    fontSize: '120%',
  },

  flex: {
    flex: 1,
  },

  badge: {
    color: colors.focus,
    transform: 'scale(0.75)',
    top: '-0.5rem',
  },
  ...mediaLarge({
    menuButton: {
      display: 'none',
    },
    cartButton: {
      display: 'none',
    },
  }),
});
