import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
// import { push } from 'react-router-redux';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

import { fetchNetOrder } from 'status/partial/orderhistory';
import formatCurrency from 'utils/formatCurrency';
import { getAmountWithCoupon } from 'utils/getCartAmount';
import {
  getOrderAmount,
} from 'utils/orderAmountTools';
import styles from './styles';

class Order extends React.PureComponent {
  componentDidMount() {
    const { auth, store } = this.props;
    this.props.getOrderList({
      contact_email: auth.user.email,
      store: store.id,
    });
  }

  toOrderPreview = (order) => {
    this.props.dispatch(push({
      pathname: '/preview_order',
      search: `order_code=${order.order_code}`,
    }));
  }

  render() {
    const { classes, orderhistory } = this.props;
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>Order</Typography>
        <Divider />
        <br />
        <div className={classes.wrap}>
          {
            get(orderhistory, 'results', []).map(item => (
              <div
                className={classes.cardWrap}
                key={item.id}
              >
                <Card
                  className={classes.card}
                  onClick={() => this.toOrderPreview(item)}
                >
                  <CardContent>
                    <Typography gutterBottom>
                      Ordre nr.: { item.order_code }
                    </Typography>
                    <Typography component="p" gutterBottom>
                      Priser DKK.
                      {
                        formatCurrency(
                          getAmountWithCoupon(
                            getOrderAmount(item),
                            get(item, 'coupon', {}),
                          ) + item.delivery_amount,
                        )
                      }
                    </Typography>
                    <Typography component="p">
                      Ordre Tid: { `${moment(item.created).format('YYYY/MM/DD HH:mm:ss')}` }
                    </Typography>
                    <Typography component="p">
                      Status: { item.status }
                    </Typography>
                    <Typography component="p">
                      Lervering: { item.order_type === 'delivery' ? 'Ja' : 'Nej' }
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))
          }
        </div>
      </React.Fragment>
    );
  }
}

Order.propTypes = {
  classes: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
  orderhistory: PropTypes.shape().isRequired,
  getOrderList: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};


export default connect(state => ({
  auth: state.auth,
  store: state.store,
  orderhistory: state.orderhistory,
}), dispatch => ({
  dispatch,
  getOrderList: bindActionCreators(fetchNetOrder, dispatch),
}))(withStyles(styles)(Order));
