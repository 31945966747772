import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import NavigationList from 'components/presentations/UserCenterSidebar/NavigationList';
import { navigatorDrawerStyle as styles } from './styles.js';

const NavigationDrawer = props => (
  <Drawer
    open={props.open}
    onClose={props.onClose}
    className={props.classes.wrapper}
  >
    <NavigationList {...props.listProps} />
  </Drawer>
);

NavigationDrawer.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
  listProps: PropTypes.shape({
    onChange: PropTypes.func,
    category: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

NavigationDrawer.defaultProps = {
  onChange: () => null,
  onClose: () => null,
  open: true,
  listProps: {
    onChange: () => null,
    category: [],
  },
};

export default withStyles(styles)(NavigationDrawer);
