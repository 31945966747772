export function setSessionStorage(key, value) {
  try {
    sessionStorage.setItem(key, value);
  } catch (error) {
    return undefined;
  }
  return undefined;
}

export function getSessionStorage(key) {
  try {
    return sessionStorage.getItem(key);
  } catch (error) {
    return null;
  }
}

export function removeSessionStorage(key) {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    return undefined;
  }
  return undefined;
}
