import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { message } from 'antd';
import UserInfomationForm from 'components/containers/forms/UserInfomation';
import patchDinerInfomation from 'apis/services/diner';
import styles from './styles';

class UserInfomation extends React.PureComponent {
  state = {
    openDeleteUser: false,
    sending: false,
  }
  async submit(values) {
    const { diner, auth } = this.props;
    this.setState({
      sending: true,
    });
    message.loading('', 30);
    try {
      await patchDinerInfomation({
        name: values.name,
        phone_number: values.phone_number,
        user: auth.user.pk,
        id: diner.id,
      });
      message.destroy();
      message.success('Vellykket drift.');
      this.setState({
        sending: false,
      });
    } catch (error) {
      message.destroy();
      message.error('Betjening mislykkedes.');
      this.setState({
        sending: false,
      });
    }
  }
  render() {
    const { diner, auth, classes } = this.props;
    const { openDeleteUser, sending } = this.state;
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>Brugerinformation</Typography>
        <div className={classes.formWrapper}>
          <Typography variant="body1" gutterBottom align="right">
            <Link to="/set_password" className={classes.light}>Skift adgangskode</Link>
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            {/*
              <span
                className={classes.light}
                onClick={() => this.setState({ openDeleteUser: true })}
              >
                Slet min konto
              </span>
            */}
          </Typography>
          <UserInfomationForm
            onSubmit={values => this.submit(values)}
            initialValues={{
              name: diner.name,
              email: auth.user.email,
              phone_number: diner.phone_number,
            }}
          />
        </div>

        <Dialog
          open={openDeleteUser}
          onClose={() => this.setState({ openDeleteUser: false })}
        >
          <DialogTitle>Slet min konto</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Du er ved at slette den nuværende konto,
              Er du sikker på at fortsætte?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDeleteUser: false })} color="default">
              Afbestille
            </Button>
            <Button onClick={() => this.setState({ openDeleteUser: false })} color="primary" disabled={sending}>
              Okay
            </Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    );
  }
}

UserInfomation.propTypes = {
  classes: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
  diner: PropTypes.shape().isRequired,
};


export default connect(state => ({
  auth: state.auth,
  diner: state.diner,
  store: state.store,
}), dispatch => ({
  dispatch,
}))(withStyles(styles)(UserInfomation));
