import { fetchCouponPromotion as getCouponPromotion } from 'apis/services/promotion';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

const FETCH_COUPON_PROMOTION = Symbol('FETCH_COUPON_PROMOTION');
const FETCH_COUPON_PROMOTION_SUCCESS = Symbol('FETCH_COUPON_PROMOTION_SUCCESS');
const RESET_COUPON_PROMOTION = Symbol('RESET_COUPON_PROMOTION');
export const actionTypes = {
  FETCH_COUPON_PROMOTION,
  FETCH_COUPON_PROMOTION_SUCCESS,
  RESET_COUPON_PROMOTION,
};


function initState() {
  return [];
}
export const initialState = initState();

export const fetchCouponPromotion = params => ({ type: FETCH_COUPON_PROMOTION, payload: params });
export const fetchCouponPromotionSuccess = response => ({
  type: FETCH_COUPON_PROMOTION_SUCCESS,
  payload: response,
});
export const resetCouponPromotion = () => ({ type: RESET_COUPON_PROMOTION });

export const fetchCouponPromotionEpic = (action$, store$) =>
  action$.ofType(FETCH_COUPON_PROMOTION).mergeMap((action) => {
    const promotion = store$.value.couponPromotion;
    if (isEmpty(promotion)) {
      return Observable.fromPromise(getCouponPromotion(action.payload))
        .map(response => fetchCouponPromotionSuccess(response));
    }
    return Observable.of(fetchCouponPromotionSuccess(promotion));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUPON_PROMOTION_SUCCESS:
      return [...action.payload];
    case RESET_COUPON_PROMOTION:
      return [];
    default:
      return state;
  }
};
