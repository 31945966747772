import { colors } from 'themes/variables';
import { mediaLarge } from 'themes/mixins';

export default theme => ({
  paper: {
    margin: 0,
  },
  title: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
  },
  content: {
    maxWidth: '96vw',
    padding: theme.spacing(2),
  },
  properties: {
    display: 'flex',
  },
  propertyValue: {
    color: colors.primary,
    marginLeft: theme.spacing(2),
  },
  extrasTable: {
    marginTop: theme.spacing(2),
    '& thead th': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  actions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    margin: 0,
  },
  unitPrice: {
    flexGrow: 1,
    color: colors.primary,
  },
  ...mediaLarge({
    content: {
      minWidth: '62vw',
    },
  }),
});
