import React from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';

class Loadmore extends React.PureComponent {
  componentDidMount() {
    const { targetNode } = this.props;
    targetNode.addEventListener('scroll', this.listener);
  }
  componentWillUnmount() {
    const { targetNode } = this.props;
    targetNode.removeEventListener('scroll', this.listener);
  }
  listener = throttle((e) => {
    if (!this.props.isActive) {
      return;
    }
    const target = e.target;
    const scrollBottom = target.scrollTop + target.clientHeight;
    const offsetBotton = target.scrollHeight - scrollBottom;
    if (offsetBotton <= this.props.offset) {
      this.props.onLoad(e);
    }
  }, 200, {
    trailing: true,
  })
  render() {
    return null;
  }
}

Loadmore.propTypes = {
  onLoad: PropTypes.func,
  targetNode: PropTypes.instanceOf(Element),
  offset: PropTypes.number,
  isActive: PropTypes.bool,
};

Loadmore.defaultProps = {
  onLoad: () => null,
  targetNode: document.body,
  offset: 100,
  isActive: true,
};

export default Loadmore;
