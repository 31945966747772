
import { colors } from 'themes/variables';

export default {
  footer: {
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 10,
    textAlign: 'center',
    backgroundColor: colors.undertone,
  },
  img: {
    width: 30,
    marginLeft: 10,
    marginRight: 10,
  },
};
