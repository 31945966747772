import React from 'react';
import { Switch } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import { UserCenterSidebar, UserCenterDrawer } from 'components/presentations/UserCenterSidebar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Infomation from './Infomation';
import Address from './Address';
import Coupon from './Coupon';
import Order from './Order';

import styles from './styles';

class UserCenter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpen: false,
    };
  }

  render() {
    const { isSidebarOpen } = this.state;
    const { classes, auth } = this.props;
    return (
      <main className={classes.wrapper}>
        <Topbar
          onMenuClick={() => this.setState({ isSidebarOpen: true })}
          position="static"
          signin
          menu
        />
        <div
          className={classes.contentWrapper}
          ref={(el) => {
            this.contentWrapperNode = el;
            return el;
          }}
        >
          <div className={classes.content}>
            <aside className={classes.asideLeft}>
              <UserCenterSidebar
                listProps={{
                  auth,
                }}
              />
            </aside>
            <div className={classes.innerWraper}>
              <Switch>
                <Route path="/user/infomation/:user" component={Infomation} />
                <Route path="/user/order/:user" component={Order} />
                <Route path="/user/configuration/:user" render={() => <div>configuration</div>} />
                <Route path="/user/address/:user" component={Address} />
                <Route path="/user/coupon/:user" component={Coupon} />
                <Redirect to={`/user/infomation/${auth.user.pk}`} />
              </Switch>
            </div>
          </div>
          <Footer />
        </div>
        <UserCenterDrawer
          open={isSidebarOpen}
          onClose={() => this.setState({ isSidebarOpen: false })}
          listProps={{
            auth,
          }}
        />
      </main>
    );
  }
}

UserCenter.propTypes = {
  classes: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
  // dispatch: PropTypes.func.isRequired,
  // store: PropTypes.shape().isRequired,
};


export default connect(state => ({
  auth: state.auth,
  store: state.store,
}), dispatch => ({
  dispatch,
}))(withStyles(styles)(UserCenter));
