import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

function DateField({ input, meta, label, ...others }) {
  const value = isEmpty(input.value) ? null : input.value;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        error={meta.submitFailed && meta.error != null}
        helperText={meta.submitFailed && meta.error ? meta.error : ''}
        {...input}
        value={value}
        fullWidth
        margin="normal"
        label={label}
        format="YYYY-MM-DD"
        {...others}
      />
    </MuiPickersUtilsProvider>
  );
}

DateField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  others: PropTypes.shape(),
  label: PropTypes.string,
};

DateField.defaultProps = {
  label: 'Dato',
  others: {},
};

export default DateField;
