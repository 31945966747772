import createHistory from 'history/createHashHistory';

const getSingletonHistory = (() => {
  let history = null;
  return () => {
    if (history === null) {
      history = createHistory();
      return history;
    }
    return history;
  };
})();

export default getSingletonHistory();
