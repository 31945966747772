
import { monochrome, footerHeight, colors } from 'themes/variables';
import { mediaLarge } from 'themes/mixins';

export default theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: monochrome.white,
    overflow: 'hidden',
  },
  contentWrapper: {
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    overflowY: 'scroll',
  },
  content: {
    minHeight: `calc(100vh - ${footerHeight}px)`,
  },
  aside: {
    display: 'none',
  },
  cardWrapper: {
    flex: '1 1 auto',
  },
  placeholder: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  deliveryInfo: {
    marginTop: theme.spacing(2),
  },
  deliveryHead: {
    ...theme.mixins.toolbar,
    display: 'none',
  },
  nonocoTips: {
    color: colors.focus,
  },
  ...mediaLarge({
    wrapper: {
      paddingBottom: 0,
    },
    content: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    asideRight: {
      display: 'block',
      margin: theme.spacing(2),
      minWidth: 450,
      maxWidth: 450,
    },
    asideLeft: {
      display: 'block',
      margin: theme.spacing(2),
      minWidth: 200,
      maxWidth: 200,
    },
    deliveryHead: {
      display: 'flex',
    },
  }),
});
