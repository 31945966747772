import React from 'react';
import PropTypes from 'prop-types';
import isIOS from 'utils/isIOS';

class IOSiFrameFixed extends React.PureComponent {
  componentDidMount() {
    const { root } = this.props;
    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.setAttribute('data-meta', 'IOSiFrameFixed');
    style.innerHTML = `
      #${root.getAttribute('id')}: {
        height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }
    `;
    if (isIOS) {
      document.getElementsByTagName('head')[0].appendChild(style);
    }
  }
  componentWillUnmount() {

  }
  render() {
    return null;
  }
}

IOSiFrameFixed.propTypes = {
  root: PropTypes.shape().isRequired,
};

export default IOSiFrameFixed;
