import { getSessionStorage } from 'utils/sessionStorage';
import getMerchandisePromotion from 'apis/services/promotion';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

const FETCH_MERCHANDISE_PROMOTION = Symbol('FETCH_MERCHANDISE_PROMOTION');
const FETCH_MERCHANDISE_PROMOTION_SUCCESS = Symbol('FETCH_MERCHANDISE_PROMOTION_SUCCESS');
const RESET_MERCHANDISE_PROMOTION = Symbol('RESET_MERCHANDISE_PROMOTION');
export const actionTypes = {
  FETCH_MERCHANDISE_PROMOTION,
  FETCH_MERCHANDISE_PROMOTION_SUCCESS,
  RESET_MERCHANDISE_PROMOTION,
};


function initState() {
  const sessionPromotion = getSessionStorage('promotion');
  if (sessionPromotion != null) {
    return JSON.parse(sessionPromotion);
  }
  return [];
}
export const initialState = initState();

export const fetchPromotion = params => ({ type: FETCH_MERCHANDISE_PROMOTION, payload: params });
export const fetchPromotionSuccess = response => ({
  type: FETCH_MERCHANDISE_PROMOTION_SUCCESS,
  payload: response,
});
export const resetPromotion = () => ({ type: RESET_MERCHANDISE_PROMOTION });

export const fetchPromotionEpic = (action$, store$) =>
  action$.ofType(FETCH_MERCHANDISE_PROMOTION).mergeMap((action) => {
    const promotion = store$.value.promotion;
    if (isEmpty(promotion)) {
      return Observable.fromPromise(getMerchandisePromotion(action.payload))
        .map(response => fetchPromotionSuccess(response));
    }
    return Observable.of(fetchPromotionSuccess(promotion));
  });

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MERCHANDISE_PROMOTION_SUCCESS:
      return [...action.payload];
    case RESET_MERCHANDISE_PROMOTION:
      return [];
    default:
      return state;
  }
};
