import { combineEpics } from 'redux-observable';
import { fetchCategoryEpic } from './category';
import { fetchDeliveryTemplateEpic } from './delivery';
import { fetchAddressesEpic } from './addresses';
import { fetchMenuEpic } from './menu';
import { fetchPromotionEpic } from './promotion';
import { fetchCouponEpic } from './coupon';
import { fetchNetOrderEpic } from './orderhistory';
import { fetchCouponPromotionEpic } from './couponpromotion';

const rootEpic = combineEpics(
  fetchCategoryEpic,
  fetchDeliveryTemplateEpic,
  fetchAddressesEpic,
  fetchMenuEpic,
  fetchPromotionEpic,
  fetchCouponEpic,
  fetchNetOrderEpic,
  fetchCouponPromotionEpic,
);

export default rootEpic;
