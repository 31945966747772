import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;
export function paid(params) {
  return request.post(`${apiUrlV1}/netorder/paid`, params);
}

export function getScanPayLink(params) {
  return request.post(`${apiUrlV1}/payment/new_scanpay_link/`, params);
}

export function getFreePayLink(params) {
  return request.post(`${apiUrlV1}/payment/new_freepay_link/`, params);
}

export default {
  paid,
  getScanPayLink,
};
