
import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;


export default function getDinerAddressList(originParams) {
  const params = {
    ...originParams,
    ...{
      page_size: 1000,
    },
  };
  return request.get(`${apiUrlV1}/diner_address/`, { params }).then(res => res.data.results);
}

export function createAddress(params) {
  return request.post(`${apiUrlV1}/diner_address/`, params);
}

export function patchAddress(params, id) {
  return request.patch(`${apiUrlV1}/diner_address/${id}/`, params);
}

export function deleteAddress(id) {
  return request.delete(`${apiUrlV1}/diner_address/${id}/`);
}
