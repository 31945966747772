import React from 'react';
import Footer from 'components/containers/FooterWrapper';
import Topbar from 'components/presentations/Topbar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import TimeForm from 'components/containers/forms/TimeNew';
import { setOrderinfo } from 'status/partial/orderinfo';
import { push } from 'connected-react-router';
import moment from 'moment';
import { isEmpty, get } from 'lodash';
import styles from './styles';


function getWaitingDuration(orderinfo, configration) {
  const type = get(orderinfo, 'order_type', 'delivery');
  if (type === 'delivery') {
    return get(configration, 'delivery_waiting_duration', 45);
  }
  return get(configration, 'carryout_waiting_duration', 45);
}

class Booking extends React.PureComponent {
  onNext = (values) => {
    if (get(values, 'asap', false)) {
      this.props.setOrderinfo(
        'plan_datetime',
        undefined, // the 'undefined' mean 'as soon as possible' on the 'get web order system'.
      );
    } else if (
      !isEmpty(get(values, 'date', '')) &&
      !isEmpty(get(values, 'hours', '')) &&
      !isEmpty(get(values, 'minutes', ''))
    ) {
      this.props.setOrderinfo(
        'plan_datetime',
        moment(`${get(values, 'date', '')} ${get(values, 'hours', '')}:${get(values, 'minutes', '')}:00`).toDate().toISOString(),
      );
    } else {
      this.props.setOrderinfo(
        'plan_datetime',
        undefined,
      );
    }
    this.props.setOrderinfo('description', values.description);
    this.props.dispatch(push({
      pathname: '/purchase',
    }));
  }

  render() {
    const { classes, orderinfo, configuration } = this.props;
    return (
      <main className={classes.wrapper}>
        <Topbar />
        <div className={classes.content}>
          <div className={classes.formWrapper}>
            <TimeForm
              onSubmit={this.onNext}
              waitingDuration={getWaitingDuration(orderinfo, configuration)}
            />
          </div>
        </div>
        <Footer />
      </main>
    );
  }
}

Booking.propTypes = {
  classes: PropTypes.shape().isRequired,
  setOrderinfo: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  orderinfo: PropTypes.shape().isRequired,
  configuration: PropTypes.shape().isRequired,
};

export default connect(
  state => ({
    orderinfo: state.orderinfo,
    store: state.store,
    configuration: state.configuration,
  }),
  dispatch => ({
    ...bindActionCreators({ setOrderinfo }, dispatch),
    dispatch,
  }),
)(withStyles(styles)(Booking));
