import { request } from 'apis/request';
import urls from 'apis/configure/urls';

const { apiUrlV1 } = urls;

export default function getCouponList(originParams) {
  const params = {
    ...originParams,
    ...{
      page_size: 1000,
      status: 'active',
    },
  };
  return request.get(`${apiUrlV1}/promotion/coupon_key/`, { params }).then(res => res.data.results);
}


export function inactiveCoupon(key) {
  return request.patch(`${apiUrlV1}/promotion/coupon_key/${key}/`, {
    status: 'inactive',
  });
}

export function getCouponItem(key) {
  return request.get(`${apiUrlV1}/promotion/coupon_key/${key}/`);
}

export function createNewCouponKey(params) {
  return request.post(`${apiUrlV1}/promotion/coupon_key/`, params);
}
