import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MailIcon from '@material-ui/icons/Mail';
import InputAdornment from '@material-ui/core/InputAdornment';

function EmailField({ input, meta }) {
  return (
    <TextField
      error={meta.submitFailed && meta.error != null}
      helperText={meta.submitFailed && meta.error ? meta.error : ''}
      label="Indtast din e-mail adresse"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <MailIcon />
          </InputAdornment>
        ),
      }}
      margin="normal"
      fullWidth
      {...input}
    />
  );
}

EmailField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
};

export default EmailField;
