import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CategoryList from 'components/presentations/CategoryList';
import styles from './styles.js';

const CategoryDrawer = props => (
  <Drawer
    open={props.open}
    onClose={props.onClose}
    className={props.classes.wrapper}
  >
    <CategoryList {...props.listProps} />
  </Drawer>
);

CategoryDrawer.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
  listProps: PropTypes.shape({
    onChange: PropTypes.func,
    category: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

CategoryDrawer.defaultProps = {
  onChange: () => null,
  onClose: () => null,
  open: true,
  listProps: {
    onChange: () => null,
    category: [],
  },
};

export default withStyles(styles)(CategoryDrawer);
