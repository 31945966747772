export default {
  root: {
    padding: '20px 16px 0 16px',
  },
  icon: {
    fontSize: '120%',
    position: 'relative',
    top: 3,
  },
  separator: {
    display: 'inline-block',
    marginLeft: 5,
    marginRight: 5,
  },
};
