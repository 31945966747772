import React from 'react';
import PropTypes from 'prop-types';
import { find, get, head, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import OrderDetailModal from 'components/presentations/OrderDetailModal';
import RemoveCarrierBagModal from 'components/presentations/CarrierBagModal/Remove';
import NextToPickupCarrierBagModal from 'components/presentations/CarrierBagModal/NextToPickup';
import NextToDeliveryCarrierBagModal from 'components/presentations/CarrierBagModal/NextToDelivery';
import formatCurrency from 'utils/formatCurrency';
import {
  // getTotalCartItemAmount,
  totalAmountOfCart,
  getTotalCartItemDiscount,
  totalDiscountOfCart,
} from 'utils/getCartAmount';
import isShowCartItemDetail from 'utils/isShowCartItemDetail';
import { getDefaultCartItem, isCarrierBagInCart } from 'status/partial/cart';
import { findCarrierBag } from 'status/partial/menu';
import styles from './styles.js';

function getDeliveryButtonColor(type, orderType) {
  if (type === orderType) {
    return 'primary';
  }
  return 'default';
}

function findMinimumOrderQuantity(menu, id) {
  const targetMerchandise = find(menu, { id });
  return get(targetMerchandise, 'minimum_order_quantity', 1);
}


function pickCarrierBagFromCart(cart) {
  const carrierBag = [];
  const merchandiseList = [];
  cart.forEach((item) => {
    if (get(item, 'sku.name', '') === 'Pose') {
      carrierBag.push(item);
    } else {
      merchandiseList.push(item);
    }
  });
  return {
    carrierBag,
    merchandiseList,
  };
}

class CartContent extends React.PureComponent {
  state = {
    isOpenDetailModal: false,
    isOpenRemoveCarrierModal: false,
    isOpenPickupCarrierModal: false,
    isOpenDeliveryCarrierModal: false,
    currentDish: getDefaultCartItem(),
  }

  openDetailModal = (cartItem) => {
    if (isShowCartItemDetail(cartItem)) {
      this.setState({
        currentDish: cartItem,
        isOpenDetailModal: true,
      });
    }
  }

  reduceCarrierBag = (item) => {
    const { removeDish, menu } = this.props;
    if (item.count === 1) {
      this.setState({
        isOpenRemoveCarrierModal: true,
      });
    } else {
      removeDish(item, findMinimumOrderQuantity(menu, get(item, 'sku.merchandise', 0)));
    }
  }

  handleRemoveCarrierBagModalOkayClick = () => {
    const { removeDish, menu, cart } = this.props;
    const carrierBagCartItem = head(cart.filter(item => item.sku.name === 'Pose'));
    this.setState({
      isOpenRemoveCarrierModal: false,
    }, () => {
      if (!isEmpty(carrierBagCartItem)) {
        removeDish(
          carrierBagCartItem,
          findMinimumOrderQuantity(menu, get(carrierBagCartItem, 'sku.merchandise', 0)),
        );
      }
    });
  }

  next = () => {
    const { onSubmit, orderinfo, configuration, cart } = this.props;
    if (
      get(orderinfo, 'order_type', '') === 'pick_up' &&
      get(configuration, 'carrierbags_in_force', false)
    ) {
      /*
        this.setState({
          isOpenPickupCarrierModal: true,
        });
      */
      onSubmit();
      return;
    }

    if (
      get(orderinfo, 'order_type', '') === 'delivery' &&
      get(configuration, 'carrierbags_in_force', false) &&
      !isCarrierBagInCart(cart)
    ) {
      this.setState({
        isOpenDeliveryCarrierModal: true,
      });
      return;
    }
    onSubmit();
  }

  render() {
    const {
      classes,
      cart,
      deliveryAllow,
      setOrderTypeToPickUp,
      setOrderTypeToDelivery,
      orderinfo,
      removeDish,
      addDish,
      promotion,
      menu,
      onSubmit,
      setCarrierBagAtLeastOne,
    } = this.props;
    const {
      isOpenDetailModal,
      currentDish,
      isOpenRemoveCarrierModal,
      isOpenPickupCarrierModal,
      isOpenDeliveryCarrierModal,
    } = this.state;
    const totalPrice = formatCurrency(totalDiscountOfCart(cart, promotion, new Date()));
    const totalOriginPrice = formatCurrency(totalAmountOfCart(cart));
    const {
      carrierBag,
      merchandiseList,
    } = pickCarrierBagFromCart(cart);

    return (
      <React.Fragment>
        <ListItem className={classes.head}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          Indkøbskurv
        </ListItem>
        <Divider />
        <List>
          <ListItem className={classes.listItem}>
            <Grid container spacing={3}>
              {deliveryAllow &&
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color={getDeliveryButtonColor('delivery', orderinfo.order_type)}
                    className={classes.submit}
                    onClick={setOrderTypeToDelivery}
                  >
                    Udbringning
                  </Button>
                  {
                    /*
                      <Typography variant="caption" align="center">
                        Kr. 0.00 / ca. 45 min.
                      </Typography>
                    */
                  }
                </Grid>
              }
              <Grid item xs={deliveryAllow ? 6 : 12}>
                <Button
                  variant="outlined"
                  color={getDeliveryButtonColor('pick_up', orderinfo.order_type)}
                  className={classes.submit}
                  onClick={setOrderTypeToPickUp}
                >
                  Afhentning
                </Button>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.next}
            >
              Gå til kassen
            </Button>
          </ListItem>
        </List>
        <Divider />
        <List component="nav">
          {
            cart.length > 0 ?
              <React.Fragment>
                {
                  carrierBag.map((item, i) => (
                    <React.Fragment
                      key={`sidebar_cart_${item.sku.id}_${item.sku.name}_${i}`}
                    >
                      <ListItem>
                        <ListItemText
                          className={classnames({
                            [classes.title]: true,
                          })}
                          primary={item.sku.name}
                        />
                        <ListItemIcon
                          className={classes.iconWrap}
                          onClick={() => this.reduceCarrierBag(item)}
                        >
                          <RemoveCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={item.count} className={classes.count} />
                        <ListItemIcon
                          className={classes.iconWrap}
                          onClick={() => addDish(item)}
                        >
                          <AddCircleIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${formatCurrency(getTotalCartItemDiscount(item, promotion, new Date()))} kr`}
                          className={classes.price}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          secondary={'Pose Hvis du ønsker din ordre bliver pakket i en pose, bedes du tilføje den til kurven. Hvis ikke, så stilles mad og tilbehør uden bærepose.'}
                        />
                      </ListItem>
                    </React.Fragment>
                  ))
                }
                {
                  merchandiseList.map((item, i) => (
                    <ListItem
                      className={classes.listItem}
                      key={`sidebar_cart_${item.sku.id}_${item.sku.name}_${i}`}
                    >
                      <ListItemText
                        className={classnames({
                          [classes.title]: true,
                          [classes.more]: isShowCartItemDetail(item),
                        })}
                        onClick={() => this.openDetailModal(item)}
                      >
                        {item.sku.name}
                      </ListItemText>
                      <ListItemIcon
                        className={classes.iconWrap}
                        onClick={() => removeDish(item, findMinimumOrderQuantity(menu, get(item, 'sku.merchandise', 0)))}
                      >
                        <RemoveCircleIcon />
                      </ListItemIcon>
                      <ListItemText primary={item.count} className={classes.count} />
                      <ListItemIcon
                        className={classes.iconWrap}
                        onClick={() => addDish(item)}
                      >
                        <AddCircleIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${formatCurrency(getTotalCartItemDiscount(item, promotion, new Date()))} kr`}
                        className={classes.price}
                      />
                    </ListItem>
                  ))
                }
              </React.Fragment>
              :
              <ListItem className={classes.listItem}>
                <ListItemText primary={'tom.'} className={classes.title} />
              </ListItem>
          }
        </List>
        <Divider />
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Priser" />
            <ListItemText
              primary={
                totalPrice === totalOriginPrice ?
                  <span>DKK {totalOriginPrice} kr</span>
                  :
                  <del>DKK {totalOriginPrice} kr</del>
              }
              className={classes.price}
            />
          </ListItem>
          {
            totalPrice !== totalOriginPrice &&
            <ListItem className={classes.listItem}>
              <ListItemText primary="Rabat Priser" />
              <ListItemText
                className={classes.price}
              >
                DKK {totalPrice} kr
              </ListItemText>
            </ListItem>
          }
        </List>
        <Divider />
        <OrderDetailModal
          open={isOpenDetailModal}
          dish={currentDish}
          onClose={() => this.setState({ isOpenDetailModal: false })}
          promotion={promotion}
        />
        <RemoveCarrierBagModal
          open={isOpenRemoveCarrierModal}
          handleOkayClick={this.handleRemoveCarrierBagModalOkayClick}
          handleCancelClick={() => this.setState({ isOpenRemoveCarrierModal: false })}
        />
        <NextToPickupCarrierBagModal
          open={isOpenPickupCarrierModal}
          handleOkayClick={onSubmit}
          handleCancelClick={() => this.setState({ isOpenPickupCarrierModal: false })}
        />
        <NextToDeliveryCarrierBagModal
          open={isOpenDeliveryCarrierModal}
          handleOkayClick={() => {
            const carrierBagSPU = findCarrierBag(menu);
            setCarrierBagAtLeastOne(
              carrierBagSPU[0].merchandisespec_set[0],
              carrierBagSPU[0].store_category,
            );
            onSubmit();
          }}
          handleCancelClick={() => this.setState({ isOpenDeliveryCarrierModal: false })}
        />
      </React.Fragment>
    );
  }
}

CartContent.propTypes = {
  classes: PropTypes.shape().isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.shape({
      properties: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      })).isRequired,
    }),
    extras: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    attachProperties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    count: PropTypes.number.isRequired,
  })),
  orderinfo: PropTypes.shape().isRequired,
  setOrderTypeToDelivery: PropTypes.func.isRequired,
  setOrderTypeToPickUp: PropTypes.func.isRequired,
  deliveryAllow: PropTypes.bool,
  removeDish: PropTypes.func.isRequired,
  addDish: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  setCarrierBagAtLeastOne: PropTypes.func.isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape()),
  menu: PropTypes.arrayOf(PropTypes.shape()),
  configuration: PropTypes.shape().isRequired,
};

CartContent.defaultProps = {
  cart: [],
  menu: [],
  orderinfo: {},
  onSubmit: () => null,
  deliveryAllow: true,
  promotion: [],
  configuration: {},
};

export default withStyles(styles)(CartContent);
