import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CartContent from 'components/presentations/CartContent';
import { omit } from 'lodash';
import styles from './styles.js';

const PurchaseSidebar = (props) => {
  const { classes } = props;
  return (
    <div
      className={classes.wrapper}
    >
      <CartContent {...omit(props, ['classes'])} />
    </div>
  );
};

PurchaseSidebar.propTypes = {
  classes: PropTypes.shape().isRequired,
};

PurchaseSidebar.defaultProps = {
};

export default withStyles(styles)(PurchaseSidebar);
