import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/filter';
import { get, isEmpty, omit } from 'lodash';
import { getOrderList } from 'apis/services/order';
import { getDefaultPaginationStatus } from 'status/schemas/pagination';

export const initialState = getDefaultPaginationStatus();

const FETCH_NET_ORDER = Symbol('FETCH_NET_ORDER');
const FETCH_NET_ORDER_SUCCESS = Symbol('FETCH_NET_ORDER_SUCCESS');

export const fetchNetOrder = params => ({ type: FETCH_NET_ORDER, payload: params });
export const fetchNetOrderSuccess = response => ({
  type: FETCH_NET_ORDER_SUCCESS,
  payload: response,
});

export const fetchNetOrderEpic = (action$, store$) => action$
  .ofType(FETCH_NET_ORDER)
  .filter((action, index) => {
    const order = store$.value.orderhistory;
    if (index === 0) {
      return true;
    }
    if (get(action, 'payload.forceRefresh', false)) {
      return true;
    }
    if (isEmpty(order.results)) {
      return false;
    }
    return get(action, 'payload.page', 0) !== get(order, 'current_page', -1);
  })
  .mergeMap(action => Observable
    .fromPromise(getOrderList(omit(action.payload, 'forceRefresh')))
    .map(response => fetchNetOrderSuccess(response)),
  );


export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NET_ORDER_SUCCESS:
      return { ...action.payload.data };
    default:
      return state;
  }
};
