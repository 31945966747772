import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NavigationList from 'components/presentations/UserCenterSidebar/NavigationList';
import { navigatorSidebarStyle as styles } from './styles.js';

const NavigationSidebar = props => (
  <div className={props.classes.wrapper}>
    <NavigationList {...props.listProps} />
  </div>
);

NavigationSidebar.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
  listProps: PropTypes.shape({
    onChange: PropTypes.func,
    category: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

NavigationSidebar.defaultProps = {
  onChange: () => null,
  onClose: () => null,
  open: true,
  listProps: {
    onChange: () => null,
    category: [],
  },
};

export default withStyles(styles)(NavigationSidebar);
